import React, { useEffect } from "react";
import "amazon-connect-streams";
import "amazon-connect-chatjs";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { PrivatePageLayout, Dashboard, Login, Call } from "./Pages";
import { doLogin, LogOut } from "./Redux/Actions";
import { OutHookConfigurator } from "./Components/OutHookConfigurator";

const Router = () => {
  const auth = useSelector((state) => state.Auth);
  const agent = useSelector((state) => state?.Agent);
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      if (auth.token) {
        dispatch(doLogin());
      }
    } catch (error) {
      dispatch(LogOut());
    }
  }, []);

  try {
    return (
      <>
        <OutHookConfigurator />
        <Routes>
          {!auth.token ||
          !auth.isCppLoaded ||
          !agent?.agentId ||
          agent.agentId.length === 0 ? (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/*" element={<Navigate to="/" replace />} />
            </>
          ) : (
            <Route path="/" element={<PrivatePageLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="call" element={<Call />} />
              <Route path="/*" element={<Navigate to="/" replace />} />
            </Route>
          )}
          {/* <Route path="/*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </>
    );
  } catch (e) {
    dispatch(LogOut());
  }
};
export default Router;
