import {
  GET_CLIENT_NAME,
  GET_PROJECT_LIST,
  GET_TICKETS_BY_CLIENT_NAME,
  LOGOUT,
} from "../actionTypes";

const INITIAL_STATE = {
  loading: false,
  projects: [],
  clients: [],
  tickets: [],
};

const RedmineReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TICKETS_BY_CLIENT_NAME:
      return {
        ...state,
        ...action.data,
      };
    case GET_CLIENT_NAME:
      return {
        ...state,
        ...action.data,
      };
    case GET_PROJECT_LIST:
      return {
        ...state,
        ...action.data,
      };

    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default RedmineReducer;
