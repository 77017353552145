import moment from "moment";
import React, { useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { handleScroll } from "../Helper/ScrollHelper";
import { getAgentTodoList, updateAgentTodoListStatus } from "../Redux/Actions";
import EditAgentToDo from "./EditAgentToDo";

export default function TodoList({ agTodoList = [] }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    agentId: "",
    id: "",
    notes: "",
    status: "",
    timestamp: "",
    title: "",
  });

  return (
    <>
      <EditAgentToDo data={state} />
      <span
        id="EditAgentTodoClick"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#EditAgentTodo"
      ></span>

      <div
        className="w-full overflow-auto"
        style={{ height: "570px" }}
        onScroll={(e) =>
          handleScroll(e, () => dispatch(getAgentTodoList(true)))
        }
      >
        <ul>
          {agTodoList.map((e, i) => {
            return (
              <li
                key={i}
                className="flex justify-between bg-white px-5 py-5 shadow-sm rounded-md mb-2"
              >
                <div className="form-check">
                  <h4>{e.title}</h4>
                  {/* <p dangerouslySetInnerHTML={{ __html: decodeURI(e.notes) }} /> */}
                  {/* <p className="text-sm mt-3">{decodeURI(e.notes)}</p> */}
                  <p className="text-sm mt-3">{e.notes}</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {e.status === "done" ? (
                      <span className="text-gray-500 cursor-pointer flex items-center text-sm mt-3 ">
                        <BsCheck2Circle className="mr-1" />
                        Done
                      </span>
                    ) : (
                      <span
                        className="text-green-500 cursor-pointer flex items-center text-sm mt-3 "
                        onClick={() =>
                          dispatch(updateAgentTodoListStatus(e.id))
                        }
                      >
                        <BsCheck2Circle className="mr-1" />
                        Mark As Done
                      </span>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <span
                    className="cursor-pointer flex items-center text-sm  mr-4 text-cyan-500"
                    onClick={() => {
                      setState(Object.assign({}, e));
                      const editAgentToDoBtn =
                        document.getElementById("EditAgentTodoClick");
                      editAgentToDoBtn.click();
                    }}
                  >
                    Edit
                  </span>
                  <span className="text-sm">
                    {moment(e.timestamp).format("LT")}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
