/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function AgStatusDropDown({
  agent,
  pcwObj,
  statusColor,
  changeAGState,
}) {
  const shadow = {
    Idle: "#e6ac00",
    Available: "#23903c",
    Offline: "#c32232",
    PCW: "#0063cc",
    Break: "#148c9f",
    Busy: "#994d00",
  };
  function MItems({ val, status }) {
    return (
      <div>
        <Menu.Item>
          {({ active }) => (
            <a
              href="#"
              className={"block px-4 py-2  text-center text-sm"}
              onClick={(e) => {
                changeAGState(val);
              }}
              style={{
                color: shadow[status],
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              {status}
            </a>
          )}
        </Menu.Item>
      </div>
    );
  }
  return (
    <Menu as="div" className="relative inline-block text-left ml-2 mr-2 w-40">
      <div>
        <Menu.Button
          style={{
            backgroundColor: statusColor,
            fontSize: 17,
            fontWeight: "bold",
          }}
          className="text-base inline-flex w-full justify-center rounded-md border border-gray-300  px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-50"
        >
          {agent?.agentCuStatusName}
          <ChevronDownIcon className="-mr-1 ml-2 h-6 w-6" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        style={{
          borderRadius: 6,
          overflow: "hidden",
        }}
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {agent?.agentCuStatusIndex === -1 &&
          agent?.agentCuStatusName !== "PCW" ? (
            <MItems
              status={agent?.agentCuStatusName}
              val={agent?.agentCuStatusIndex}
            />
          ) : agent?.agentCuStatusName === "PCW" ? (
            agent?.agentStatus?.length > 0 &&
            agent.agentStatus
              .concat([pcwObj])
              .map((e, i) => <MItems status={e.name} val={i} />)
          ) : (
            agent?.agentStatus?.length > 0 &&
            agent.agentStatus.map((e, i) => <MItems status={e.name} val={i} />)
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
