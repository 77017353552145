import React, { useState, useRef, useEffect } from "react";
import styles from "../styles/AudioPlayer.module.css";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import moment from "moment";
import { isValidHttpUrl } from "../Helper/Others";
import Loader from "./Loader";

const CustomAudioPlayer = ({ sdActivity }) => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  // references
  const audioPlayer = useRef(); // reference our audio component
  const progressBar = useRef(); // reference our progress bar
  const animationRef = useRef(); // reference the animation

  const [errorMsg, setErrorMsg] = useState("true");
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const seconds = Math.floor(audioPlayer.current.duration);
  //   setDuration(seconds);
  //   progressBar.current.max = seconds;
  // }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

  useEffect(() => {
    testRecordingLink();
  }, [sdActivity.contactId]);

  const testRecordingLink = () => {
    console.log("start");
    setLoading(true);
    setErrorMsg("true");
    if (
      sdActivity?.recordingLink &&
      sdActivity.recordingLink !== "N/A" &&
      isValidHttpUrl(sdActivity.recordingLink) &&
      sdActivity.recordingLink.indexOf("/[object Object]") === -1 &&
      sdActivity.recordingLink.indexOf("/null") === -1
    ) {
      setErrorMsg("");
    } else {
      setErrorMsg("Recording link unavailable.");
    }
    setLoading(false);
  };

  const initAudio = () => {
    const seconds = Math.floor(audioPlayer.current.duration);
    setDuration(seconds);
    progressBar.current.max = seconds;
  };
  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value / duration) * 100}%`
    );
    setCurrentTime(progressBar.current.value);
  };
  if (loading) {
    <Loader />;
  }

  return (
    <div className="w-1/2 mr-2">
      <div className="w-full overflow-auto" style={{ height: "450px" }}>
        <ul>
          <li className="border p-4 rounded-md bg-white shadow-md">
            {errorMsg.length > 0 ? (
              <p
                style={{
                  fontSize: 25,
                  fontWeight: "500",
                  color: "rgb(88 122 134)",
                  height: 65,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {errorMsg}
              </p>
            ) : (
              <>
                <div className="w-full flex  justify-between items-center">
                  <audio
                    ref={audioPlayer}
                    src={sdActivity.recordingLink}
                    preload="metadata"
                    onLoadedMetadata={() => {
                      initAudio();
                    }}
                  ></audio>
                  <button
                    className="p-4 bg-indigo-900 rounded-full text-white  "
                    style={{ backgroundColor: "#033140" }}
                    onClick={togglePlayPause}
                  >
                    {isPlaying ? <FaPause size={20} /> : <FaPlay size={20} />}
                  </button>

                  {/* current time */}
                  <div className={styles.currentTime}>
                    {calculateTime(currentTime)}
                  </div>

                  {/* progress bar */}
                  <div>
                    <input
                      type="range"
                      className={styles.progressBar}
                      defaultValue="0"
                      ref={progressBar}
                      onChange={changeRange}
                    />
                  </div>
                  {/* duration */}
                  <div className={styles.duration}>
                    {duration && !isNaN(duration) && calculateTime(duration)}
                  </div>
                  <p className="text-sm">
                    {moment(sdActivity.timeStamp).format("MMM-DD-YYYY [at] LT")}
                    {/* Sep-23-2022 at 5:30AM */}
                  </p>
                </div>
              </>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CustomAudioPlayer;
