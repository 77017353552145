/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { LogoImg, ProfileImg } from "../Assets/Images";
import { BiSearch } from "react-icons/bi";
import { FaAngleDown } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { LogOut } from "../Redux/Actions/AuthActions";
import { useGoogleLogout } from "react-google-login";
import { updateAgentStatus } from "../Redux/Actions";
import { Link } from "react-router-dom";
import CommingSoonPopUp from "./CommingSoonPopUp";
import { ConfirmAlertPCWToAv, openBtn } from "./ConfirmAlertPCWToAv";
import AgStatusDropDown from "./AgStatusDropDown";

export default function Navbar(props) {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth);
  const agent = useSelector((state) => state.Agent);
  const [cuStatus, setcuStatus] = useState(null);

  const onLogoutSuccess = () => {
    console.log("onLogoutSuccess");
  };

  const onFailure = () => {
    console.log("onFailure");
  };
  const { signOut, loaded } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  const logout = () => {
    dispatch(LogOut());
    loaded && signOut();
  };

  const shadow = {
    Idle: "#FFC107",
    Available: "#28A745",
    Offline: "#DC3545",
    PCW: "#007BFF",
    Break: "#17A2B8",
    Busy: "#cc6600",
    MissedCallAgent: "#DC3545",
  };
  const pcwObj = {
    agentStateARN: "",
    type: "routable",
    name: "PCW",
    startTimestamp: null,
  };
  const statusName = agent?.agentStatus[agent?.agentCuStatusIndex]?.name;
  const statusColor =
    agent?.agentCuStatusIndex === -1
      ? shadow[agent?.agentCuStatusName]
      : shadow[statusName];

  const setStatus = () => {
    changeAGState(cuStatus, false);
  };

  const changeAGState = (index, isCheck = true) => {
    if (agent?.agentCuStatusName === "PCW" && isCheck) {
      setcuStatus(index);
      openBtn();
      return;
    }
    console.log("agent.agentStatus", agent.agentStatus[index]);
    const connect = window.connect;
    const agentCpp = new connect.Agent();
    agentCpp.setState(
      agent.agentStatus[index],
      {
        success: async () => {
          await updateAgStatus(agent.agentStatus[index].name);
        },
        failure: function (err) {
          console.log("status change err", err);
        },
      },
      { enqueueNextState: false }
    );
  };

  const updateAgStatus = async (statusName) => {
    dispatch(updateAgentStatus(statusName));
  };

  return (
    <>
      <ConfirmAlertPCWToAv fn={() => setStatus()} />
      <div
        className={`main-nav w-full flex justify-between bg-white pl-6 pr-24 py-3 z-50`}
        style={{ boxShadow: `0px 4px 16px ${statusColor}` }}
      >
        <div className="flex items-center">
          <Link to={"/"}>
            <img src={LogoImg} alt="logo" className="mr-10 w-28" />
          </Link>
          <h2 className="text-2xl font-bold">
            BizCloud Contact Center {process.env.REACT_APP_API_KEY}
          </h2>
        </div>
        <div className="flex items-center">
          <CommingSoonPopUp placement="left">
            <button>
              <BiSearch className="w-8 h-8" />
            </button>
          </CommingSoonPopUp>

          <AgStatusDropDown
            agent={agent}
            pcwObj={pcwObj}
            statusColor={statusColor}
            changeAGState={changeAGState}
            shadow={shadow}
          />

          <div
            className="flex justify-center items-center cursor-pointer"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="flex w-14 h-14 rounded-full dropdown-toggle justify-center items-center mr-2">
              <img
                className="w-12 h-12 rounded-full "
                src={auth.profileObj.imageUrl ?? ProfileImg}
                referrerpolicy="no-referrer"
                alt="profile img"
              />
            </div>

            <FaAngleDown className="w-7 h-7" />
          </div>
          <ul
            className="
            dropdown-menu
            min-w-max
            absolute  
            hidden
            bg-white
            text-base
            z-50
            float-left
            py-2
            list-none
            text-left
            rounded-lg
            shadow-lg
            mt-1
            hidden
            m-0
            bg-clip-padding
            border-none
          "
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <button
                className="
                flex items-center
                dropdown-item
                py-2
                px-4
                font-normal
                w-full
                whitespace-nowrap
                bg-transparent
                text-gray-700
                hover:bg-gray-100
              "
                onClick={() => {
                  logout();
                }}
              >
                <FiLogOut className="w-5 h-5 mr-3" />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
