import {
  GET_CUSTOMER_DETAILS_BY_MOB,
  SET_MOB,
  SET_CALL_CONTACT_DATA,
  LOGOUT,
  RESET_CALL_REDUCER,
  SET_CALL_STATUS,
  SET_CALL_LOG,
} from "../actionTypes";

const INITIAL_STATE = {
  loading: false,
  phoneNumber: "",
  cuCustomer: null,
  tickets: [],
  contactState: null,
  callStatus: false,
  cuCallLog: {},
  isCallAccepted: false,
};

const CallReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_DETAILS_BY_MOB:
      return {
        ...state,
        ...action.data,
      };
    case SET_MOB:
      return {
        ...state,
        ...action.data,
      };
    case SET_CALL_CONTACT_DATA:
      return {
        ...state,
        ...action.data,
      };

    case SET_CALL_STATUS:
      return {
        ...state,
        ...action.data,
      };

    case SET_CALL_LOG:
      return {
        ...state,
        ...action.data,
      };

    case RESET_CALL_REDUCER:
      return INITIAL_STATE;

    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default CallReducer;
