import React from "react";
import { useSelector, useDispatch } from "react-redux";
import AgentScriptList from "../../Components/AgentScriptList";
import ContactHistoryList from "../../Components/ContactHistoryList";
import NotificationCallPage from "../../Components/NotificationCallPage";
import CommingSoonPopUp from "../../Components/CommingSoonPopUp";
import { getAgentListScripts } from "../../Redux/Actions";
import PostCallAgileTicket from "../../Components/PostCallAgileTicket";
import TodoList from "../../Components/TodoList";
import { handleScroll } from "../../Helper/ScrollHelper";
import AddAgentToDo from "../../Components/AddAgentToDo";
import { MdEdit } from "react-icons/md";
import LeadsCallPage from "../../Components/LeadsCallPage";

export default function CallScreenComponent() {
  const dispatch = useDispatch();
  const agent = useSelector((state) => state.Agent);
  const call = useSelector((state) => state.Call);

  return (
    <div className="w-2/3">
      <div className="w-full">
        <ul
          className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4"
          id="tabs-Agile-ticket"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <a
              href="#tabs-home3"
              className="
              nav-link
              w-full
              block
              font-medium
              text-lg
              leading-tight
              border-x-0 border-t-0 border-b-2 border-transparent
              px-6
              py-3
              my-2
              hover:border-transparent hover:bg-gray-100
              focus:border-transparent
              active
            "
              id="tab-Agile-ticket"
              data-bs-toggle="pill"
              data-bs-target="#tab-Agile-ticket-panel"
              role="tab"
              aria-controls="tab-Agile-ticket-panel"
              aria-selected="true"
            >
              Agile
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#tabs-tolist"
              className="nav-link
              w-full
              block
              font-medium
              text-lg
              leading-tight
              border-x-0 border-t-0 border-b-2 border-transparent
              px-6
              py-3
              my-2
              hover:border-transparent hover:bg-gray-100
              focus:border-transparent
            "
              id="tab-todolist"
              data-bs-toggle="pill"
              data-bs-target="#tabs-todolist-panel"
              role="tab"
              aria-controls="tabs-todolist-panel"
              aria-selected="false"
            >
              To-Do List
            </a>
          </li>
        </ul>
        <div className="w-full">
          <div className="tab-content" id="tabs-tabContent3">
            {/* Ticket Agile */}

            <div
              className="tab-pane fade show active"
              id="tab-Agile-ticket-panel"
              role="tabpanel"
              aria-labelledby="tab-Agile-ticket"
            >
              {call?.cuCustomer?.client && <PostCallAgileTicket call={call} />}
            </div>
            {/* Todo List */}
            <div
              className="tab-pane fade overflow-auto"
              id="tabs-todolist-panel"
              role="tabpanel"
              aria-labelledby="tabs-todolist-panel"
              style={{ height: 400 }}
            >
              <AddAgentToDo />
              <MdEdit
                style={{
                  position: "absolute",
                  top: "160px",
                  right: "100px",
                }}
                className=" cursor-pointer text-cyan-600 text-2xl"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCenter"
              />
              <TodoList agTodoList={agent?.agTodoList} />
            </div>
          </div>
        </div>
      </div>
      {call?.phoneNumber && (
        <div className="w-full mb-6">
          <ul
            className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4"
            id="tabs-tab3234"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                href="#tabs-Script"
                className="
                        nav-link
                        w-full
                        block
                        font-medium
                        text-lg
                        leading-tight
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                        active
                      "
                id="tabs-Script-tab3"
                data-bs-toggle="pill"
                data-bs-target="#tabs-Script-panel"
                role="tab"
                aria-controls="tabs-Script-panel"
                aria-selected="true"
              >
                Script
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                href="#tabs-History"
                className="
                      nav-link
                      w-full
                      block
                      font-medium
                      text-lg
                      leading-tight
                      border-x-0 border-t-0 border-b-2 border-transparent
                      px-6
                      py-3
                      my-2
                      hover:border-transparent hover:bg-gray-100
                      focus:border-transparent
                    "
                id="tabs-History-123"
                data-bs-toggle="pill"
                data-bs-target="#tabs-History-panel"
                role="tab"
                aria-controls="tabs-History-panel"
                aria-selected="false"
              >
                History
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                href="#tabs-Notification"
                className="
                      nav-link
                      w-full
                      block
                      font-medium
                      text-lg
                      leading-tight
                      border-x-0 border-t-0 border-b-2 border-transparent
                      px-6
                      py-3
                      my-2
                      hover:border-transparent hover:bg-gray-100
                      focus:border-transparent
                    "
                id="tabs-notification-tab945"
                data-bs-toggle="pill"
                data-bs-target="#tabs-Notification-panel"
                role="tab"
                aria-controls="tabs-Notification-panel"
                aria-selected="false"
              >
                Notification
              </a>
            </li>
            {agent?.agentConfigCpp?.queueType === "Sales" && (
              <li className="nav-item" role="presentation">
                <a
                  href="#tabs-Notification"
                  className="
                      nav-link
                      w-full
                      block
                      font-medium
                      text-lg
                      leading-tight
                      border-x-0 border-t-0 border-b-2 border-transparent
                      px-6
                      py-3
                      my-2
                      hover:border-transparent hover:bg-gray-100
                      focus:border-transparent
                    "
                  id="tabs-leads"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-leads-panel"
                  role="tab"
                  aria-controls="tabs-leads-panel"
                  aria-selected="false"
                >
                  Leads
                </a>
              </li>
            )}
            <li className="nav-item" role="presentation">
              <CommingSoonPopUp placement="right">
                <a
                  href="#tabs-home9"
                  className="
                  nav-link
                  w-full
                  block
                  font-medium
                  text-lg
                  leading-tight
                  border-x-0 border-t-0 border-b-2 border-transparent
                  px-6
                  py-3
                  my-2
                  hover:border-transparent hover:bg-gray-100
                  focus:border-transparent
                "
                  id="tabs-Transcript-tab9234"
                  // data-bs-toggle="pill"
                  // data-bs-target="#tabs-Transcript"
                  // role="tab"
                  // aria-controls="tabs-Transcript"
                  // aria-selected="false"
                >
                  <span style={{ color: "gray" }}>Transcript</span>
                </a>
              </CommingSoonPopUp>
            </li>
          </ul>
          <div className="w-full">
            <div className="tab-content overflow-auto" id="tabs-tabContent3">
              <div
                className="tab-pane fade show active"
                id="tabs-Script-panel"
                role="tabpanel"
                aria-labelledby="tabs-Script-panel"
                style={{ height: 500 }}
                onScroll={(e) =>
                  handleScroll(e, () => dispatch(getAgentListScripts(true)))
                }
              >
                <AgentScriptList data={agent?.agScriptList} />
              </div>
              <div
                className="tab-pane fade"
                id="tabs-History-panel"
                role="tabpanel"
                aria-labelledby="tabs-History-panel"
              >
                <ContactHistoryList data={agent?.contactHistory} />
              </div>
              <div
                className="tab-pane fade"
                id="tabs-Notification-panel"
                role="tabpanel"
                aria-labelledby="tabs-Notification-panel"
              >
                <NotificationCallPage agent={agent} call={call} />
              </div>
              {agent?.agentConfigCpp?.queueType === "Sales" && (
                <div
                  className="tab-pane fade"
                  id="tabs-leads-panel"
                  role="tabpanel"
                  aria-labelledby="tabs-leads-panel"
                >
                  <LeadsCallPage />
                </div>
              )}
              <div
                className="tab-pane fade"
                id="tabs-Transcript"
                role="tabpanel"
                aria-labelledby="tabs-Transcript"
              >
                <CommingSoonPopUp>Coming Soon</CommingSoonPopUp>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full mt-6">
        <h2 className="text-2xl font-bold pl-5">Recommendations</h2>
      </div>
      <div
        className="w-full overflow-auto"
        // style={{ height: 445 }}
      >
        <CommingSoonPopUp>
          <div className="w-full bg-white rounded-md shadow-sm py-5 px-4 mb-5">
            Coming Soon
            <h4 className="font-bold pb-2">{/* <ComingSoon /> */}</h4>
          </div>
        </CommingSoonPopUp>
        {/* <div className="w-full bg-white rounded-md shadow-sm py-5 px-4 mb-5">
                <h4 className="font-bold pb-2">
                  Ask suer for the open settings
                </h4>
                <nav className="bg-grey-light rounded-md w-full">
                  <ol className="list-reset flex items-center">
                    <li>
                      <a href="#" className="text-black">
                        Open Setting
                      </a>
                    </li>
                    <li className="mx-4 text-emerald-400">
                      <FaAngleRight />
                    </li>
                    <li>
                      <a href="#" className="text-black">
                        Go to Account
                      </a>
                    </li>
                    <li className="mx-4 text-emerald-400">
                      <FaAngleRight />
                    </li>
                    <li>
                      <a href="#" className="text-black">
                        Open Profile
                      </a>
                    </li>
                    <li className="mx-4 text-emerald-400">
                      <FaAngleRight />
                    </li>
                    <li>
                      <a href="#" className="text-black">
                        Change Password
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="w-full bg-white rounded-md shadow-sm py-5 px-4 mb-5">
                <h4 className="font-bold pb-2">
                  Ask suer for the open settings
                </h4>
                <nav className="bg-grey-light rounded-md w-full">
                  <ol className="list-reset flex items-center">
                    <li>
                      <a href="#" className="text-black">
                        Open Setting
                      </a>
                    </li>
                    <li className="mx-4 text-emerald-400">
                      <FaAngleRight />
                    </li>
                    <li>
                      <a href="#" className="text-black">
                        Go to Account
                      </a>
                    </li>
                    <li className="mx-4 text-emerald-400">
                      <FaAngleRight />
                    </li>
                    <li>
                      <a href="#" className="text-black">
                        Open Profile
                      </a>
                    </li>
                    <li className="mx-4 text-emerald-400">
                      <FaAngleRight />
                    </li>
                    <li>
                      <a href="#" className="text-black">
                        Change Password
                      </a>
                    </li>
                  </ol>
                </nav>
              </div> */}
      </div>
    </div>
  );
}
