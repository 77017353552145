/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { async } from "regenerator-runtime";
import { handleScroll } from "../Helper/ScrollHelper";
import { showError } from "../Helper/Tost";
import {
  getAsigneeListService,
  getTicketListService,
} from "../Services/redmineServices";
import AsigneeDropdown from "./AsigneeDropdown";
import { ShoWErrorMsg } from "./HelperComponent";
import Loader from "./Loader";

export default function CallPageSearchSingleTicket() {
  const redmine = useSelector((state) => state.Redmine);
  const [selectedVal, setselectedVal] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSearch, seIsSearch] = useState(false);
  const [selecetAsignee, setSelectedasignee] = useState(null);
  const initialTicket = {
    issues: [],
    page: 1,
    limit: 5,
    total: 0,
    msg: "Ticket details retrieved successfully.",
  };
  const [ticketList, setTicketList] = useState(initialTicket);

  const fields = [
    { value: "issue_id", name: "Ticket Id" },
    { value: "cf_8", name: "Client" },
    { value: "assigned_to_id", name: "Assignee" },
  ];

  const errType = {
    issue_id: "Please Enter Ticket Id",
    cf_8: "Please Select Client",
    assigned_to_id: "Please Select Asignee",
    "": "Select Search By",
  };

  const searchTicket = async (loadMore = false) => {
    try {
      seIsSearch(true);
      setLoading(true);
      if (selectedVal.length === 0 && searchVal.length === 0) {
        showError("Please put the required details.");
      } else {
        if (
          selectedVal !== "issue_id" &&
          ticketList.total !== 0 &&
          ticketList.page * ticketList.limit >= ticketList.total
        ) {
          console.log("overload");
          return;
        }
        if (selectedVal === "issue_id") setTicketList(initialTicket);

        const page = loadMore ? ticketList.page + 1 : ticketList.page;
        const data = await getTicketListService(selectedVal, searchVal, page);
        const issues = data.data.issues;
        setTicketList((prevState) => ({
          ...prevState,
          page: issues.length > 0 ? page : prevState.page,
          issues: loadMore ? prevState.issues.concat(issues) : issues,
          total: data.data.total,
        }));
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const setSelectType = (val) => {
    setselectedVal(val);
    setSearchVal("");
    setSelectedasignee(null);
    setTicketList(initialTicket);
    seIsSearch(false);
    setLoading(false);
  };

  useEffect(() => {
    getAsignee();
  }, []);

  useEffect(() => {
    if (selectedVal === "cf_8" || selectedVal === "assigned_to_id") {
      setTicketList(initialTicket);
    }
  }, [searchVal]);

  const getAsignee = async () => {
    const data = await getAsigneeListService(1);
    console.log("data", data);
  };

  return (
    <div className="w-full mt-3 overflow-hidden" style={{ height: 324 }}>
      {/* <p className="mb-2">Search Ticket</p> */}
      <div className="w-full flex justify-between ">
        <select
          className="form-select appearance-none w-1/4 mr-2 ml-1
                      block
                      px-3
                      py-2.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding bg-no-repeat
                      border border-solid border-gray-300
                      transition
                      ease-in-out
                      m-0
                      focus:outline-none rounded-lg
                      focus:text-gray-700 focus:bg-white focus:border-black "
          value={selectedVal}
          onChange={(e) => {
            setSelectType(e.target.value);
          }}
        >
          <option value={""}>Search by...</option>
          {fields.map((e, i) => (
            <option key={i} value={e.value}>
              {e.name}
            </option>
          ))}
        </select>
        <div class="input-group relative flex flex-wrap w-4/6 mr-2">
          {selectedVal === "issue_id" && (
            <input
              type="search"
              class="form-control rounded relative border border-solid border-gray-300 flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding  transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
              placeholder="Search"
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
            />
          )}
          {selectedVal === "cf_8" && (
            <select
              style={{ width: "100%" }}
              className="form-select appearance-none m-0
                      block
                      px-3
                      py-2.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding bg-no-repeat
                      border border-solid border-gray-300
                      transition
                      ease-in-out
                      m-0
                      focus:outline-none rounded-lg
                      focus:text-gray-700 focus:bg-white focus:border-black"
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
            >
              <option value="">Select Client</option>
              {redmine?.clients &&
                redmine.clients.map((e, i) => (
                  <option key={i} value={e.value}>
                    {e.label}
                  </option>
                ))}
            </select>
          )}
          {selectedVal === "assigned_to_id" && (
            <AsigneeDropdown
              selectedOption={selecetAsignee}
              setSelectedOption={(e) => {
                setSelectedasignee(e);
                setSearchVal(e.id);
              }}
              disabled={selectedVal.length === 0}
            />
          )}
        </div>

        {selectedVal.length > 0 && (
          <button
            disabled={selectedVal.length === 0 || searchVal.length === 0}
            className={
              selectedVal.length === 0 || searchVal.length === 0
                ? "border-none bg-gray-500 text-white rounded-md h-12 w-2/6"
                : "border-none bg-cyan-500 text-white rounded-md h-12 w-2/6"
            }
            onClick={() => searchTicket()}
          >
            Search
          </button>
        )}
      </div>

      <div className="border mt-3 mb-3"></div>
      <p className="text-sm mb-3">Search Result</p>
      <div
        style={{ height: 180, overflow: "scroll" }}
        onScroll={(e) => isSearch && handleScroll(e, () => searchTicket(true))}
      >
        {ticketList.issues.length > 0 &&
          ticketList.issues.map((e, i) => (
            <div key={i} className="bg-white border px-5 py-3 rounded-lg mb-3">
              <div className="w-full flex justify-between">
                <div>
                  <h4 className="text-lg">{e.subject}</h4>
                  <p className="text-sm mt-3">{e.description}</p>
                </div>
                <div>
                  <a
                    href={"https://agiledev.bizcloudexperts.com/issues/" + e.id}
                    target={"_blank"}
                    className="text-md underline text-cyan-500"
                    rel="noreferrer"
                  >
                    #{e.id}
                  </a>
                </div>
              </div>
            </div>
          ))}
        {isSearch && !loading && ticketList.issues.length === 0 && (
          <ShoWErrorMsg msg={"No ticket found"} />
        )}
        {!isSearch && <ShoWErrorMsg msg={errType[selectedVal]} />}
        {loading && <Loader />}
      </div>
    </div>
  );
}
