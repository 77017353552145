import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgentListScripts } from "../../Redux/Actions";
import AgentCCP from "../../Components/AgentCCP";
import CallPageForm from "../../Components/CallPageForm";
import CallScreenComponent from "./CallScreenComponent";

export default function Index() {
  const dispatch = useDispatch();
  const call = useSelector((state) => state.Call);

  useEffect(() => {
    dispatch(getAgentListScripts());
    const containerDiv = document.getElementById("container-div-1");
    const newContainerDiv = document.getElementById("new-container-div-1");
    newContainerDiv.appendChild(containerDiv.children[0].cloneNode(true));
    return () => {
      newContainerDiv.remove();
    };
  }, []);

  return (
    <>
      <div className="full-body w-full bg-gray-50">
        <div className="pl-5 pr-24 pt-8 bg-gray-50 flex">
          <AgentCCP />
          {call.phoneNumber.length > 0 &&
            (call.cuCustomer ? <CallScreenComponent /> : <CallPageForm />)}
        </div>
      </div>
    </>
  );
}
