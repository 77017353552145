import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showInfo, showSuccess } from "../Helper/Tost";
import { createAgentJourney } from "../Redux/Actions";
import { SET_CALL_LOG } from "../Redux/actionTypes";
import {
  createTicketService,
  updateTicketService,
} from "../Services/redmineServices";
import CallPageSearchSingleTicket from "./CallPageSearchSingleTicket";
import TicketsDropdown from "./TicketsDropdown";

export default function PostCallAgileTicket() {
  const dispatch = useDispatch();
  // const agent = useSelector((state) => state.Agent);
  const call = useSelector((state) => state.Call);
  const redmine = useSelector((state) => state.Redmine);
  const { cuCustomer, cuCallLog } = call;

  const [isSelect, setIsSelect] = useState(true);
  const [isTicket, setIsTicket] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState("");

  const [newTicket, setNewTicket] = useState({
    subject: "",
    description: "",
  });

  const [selectclient, setSelectClient] = useState("");
  // const [selectProject, setSelectProject] = useState("");
  const [selectProject, setSelectProject] = useState(72);

  useEffect(() => {
    setSelectedTicket("");
    setNewTicket((prev) => ({
      subject: "",
      description: "",
    }));
    setSelectClient("");
    // setSelectProject("");
  }, [isSelect]);

  const resetState = async () => {
    //all states
    setSelectedTicket("");
    setNewTicket((prev) => ({
      subject: "",
      description: "",
    }));
    setSelectClient("");
    // setSelectProject("");
  };

  const createTicket = async () => {
    if (
      !isSelect &&
      newTicket.subject.length > 0 &&
      newTicket.description.length > 0
    ) {
      const clientIdList = redmine.clients.filter(
        (e) => e.value === selectclient
      );
      const clientId = clientIdList.length > 0 ? clientIdList[0].id : 8;
      const payload = {
        ...newTicket,
        projectId: selectProject,
        clientName: selectclient,
        customFields: [
          {
            id: clientId,
            value: [selectclient],
          },
        ],
      };
      const res = await createTicketService(payload);
      console.log("newTicket:createTicketService", res);
      if (res.status === 200) {
        updateTicketStoreAndPCWv(res.data.id.toString());
        resetState();
        showSuccess("Ticket created");
      } else {
        showError("Failed to create Ticket");
      }
    } else {
      showInfo("Please fill the ticket details");
    }
  };

  const updateTicket = async () => {
    if (
      isSelect &&
      newTicket.description.length > 0 &&
      selectedTicket?.id !== ""
    ) {
      const payload = {
        notes: newTicket.description,
        clientName: cuCustomer?.client,
      };
      const res = await updateTicketService(selectedTicket.id, payload);
      if (res.status === 200) {
        updateTicketStoreAndPCWv(selectedTicket.id.toString());
        resetState();
        showSuccess("Ticket Updated");
      } else {
        showError("Failed to Update the Ticket");
      }
    } else {
      showInfo("Please fill the ticket details");
    }
  };

  const updateTicketStoreAndPCWv = (ticketId) => {
    dispatch({
      type: SET_CALL_LOG,
      data: {
        cuCallLog: {
          ...cuCallLog,
          redmineNumber: ticketId,
        },
      },
    });
    dispatch(
      createAgentJourney({
        contactStatus: "postCallWork",
        activity: "postCallWork",
        redmineNumber: ticketId,
      })
    );
  };

  return (
    <div className="w-full flex">
      <div
        className="w-full mr-2 p-5 rounded-md shadow-md bg-white"
        style={{ height: "390px" }}
      >
        <div className="w-full flex">
          <div className="form-check">
            <input
              className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              name="selectTicket"
              id="selectTicket"
              checked={isSelect && !isTicket}
              defaultChecked={isSelect}
              onClick={() => {
                setIsTicket(false);
                setIsSelect(true);
              }}
            />
            <label
              className="form-check-label inline-block text-black text-sm"
              htmlFor="selectTicket"
            >
              Select ticket
            </label>
          </div>
          <div className="form-check ml-4">
            <input
              className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              name="createTicket"
              id="createTicket"
              checked={!isSelect && !isTicket}
              onClick={() => {
                setIsTicket(false);
                setIsSelect(false);
              }}
            />
            <label
              className="form-check-label inline-block text-black text-sm"
              htmlFor="createTicket"
            >
              Create ticket
            </label>
          </div>
          <div className="form-check ml-4">
            <input
              className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              name="findTicket"
              id="findTicket"
              checked={isTicket}
              onClick={() => setIsTicket(true)}
            />
            <label
              className="form-check-label inline-block text-black text-sm"
              htmlFor="findTicket"
            >
              Search Ticket
            </label>
          </div>
        </div>
        {isTicket ? (
          <CallPageSearchSingleTicket />
        ) : isSelect ? (
          <>
            <div className="w-full flex pt-4">
              <div className="w-1/2 mr-4">
                <div className="mb-3">
                  <label
                    htmlFor="heading"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Project
                  </label>
                  <select
                    className="form-select appearance-none
                                  block
                                  w-full
                                  px-3
                                  py-2.5
                                  text-base
                                  font-normal
                                  text-gray-700
                                  bg-white bg-clip-padding bg-no-repeat
                                  border border-solid border-gray-300
                                  transition
                                  ease-in-out
                                  m-0
                                  focus:outline-none rounded-lg
                                  focus:text-gray-700 focus:bg-white focus:border-black "
                    aria-label="Default select example"
                    value={selectProject}
                    onChange={(e) => setSelectProject(e.target.value)}
                    disabled={true}
                  >
                    <option value="">Select Project</option>
                    {redmine?.projects?.length > 0 &&
                      redmine.projects.map((e, i) => (
                        <option key={i} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="w-1/2 mr-4">
                <div className="mb-3">
                  <label
                    htmlFor="heading"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Client
                  </label>
                  <select
                    className="form-select appearance-none
                                  block
                                  w-full
                                  px-3
                                  py-2.5
                                  text-base
                                  font-normal
                                  text-gray-700
                                  bg-white bg-clip-padding bg-no-repeat
                                  border border-solid border-gray-300
                                  transition
                                  ease-in-out
                                  m-0
                                  focus:outline-none rounded-lg
                                  focus:text-gray-700 focus:bg-white focus:border-black "
                    aria-label="Default select example"
                    value={selectclient}
                    onChange={(e) => setSelectClient(e.target.value)}
                  >
                    <option value="">Select Client</option>

                    {redmine?.clients &&
                      redmine.clients.map((e, i) => (
                        <option key={i} value={e.value}>
                          {e.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="w-1/2 mr-4">
                <div className="mb-3">
                  <label
                    htmlFor="heading"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Select Ticket
                  </label>
                  <TicketsDropdown
                    clientName={selectclient}
                    projectId={selectProject}
                    selectedOption={selectedTicket}
                    setSelectedOption={(e) => setSelectedTicket(e)}
                    disabled={
                      selectclient.length === 0 || selectProject.length === 0
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full pt-2">
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
              >
                Add Description
              </label>
              <textarea
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                id="exampleFormControlTextarea1"
                rows="5"
                placeholder="Type Here..."
                onChange={(e) => {
                  setNewTicket({
                    ...newTicket,
                    description: e.target.value,
                  });
                }}
                value={newTicket.description}
              ></textarea>
            </div>
            <div className="w-full pt-6 flex justify-end">
              <button
                className=" border-none bg-cyan-500 text-white py-2 px-10 rounded-md"
                onClick={() => updateTicket()}
              >
                Update
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="w-full flex pt-4">
              <div className="w-1/2 mr-4">
                <div className="mb-3">
                  <label
                    htmlFor="heading"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Project
                  </label>
                  <select
                    className="form-select appearance-none
                                  block
                                  w-full
                                  px-3
                                  py-2.5
                                  text-base
                                  font-normal
                                  text-gray-700
                                  bg-white bg-clip-padding bg-no-repeat
                                  border border-solid border-gray-300
                                  transition
                                  ease-in-out
                                  m-0
                                  focus:outline-none rounded-lg
                                  focus:text-gray-700 focus:bg-white focus:border-black "
                    aria-label="Default select example"
                    value={selectProject}
                    onChange={(e) => setSelectProject(e.target.value)}
                    disabled={true}
                  >
                    <option value="">Select Project</option>
                    {redmine?.projects?.length > 0 &&
                      redmine.projects.map((e, i) => (
                        <option key={i} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="w-1/2 mr-4">
                <div className="mb-3">
                  <label
                    htmlFor="heading"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Client
                  </label>
                  <select
                    className="form-select appearance-none
                                  block
                                  w-full
                                  px-3
                                  py-2.5
                                  text-base
                                  font-normal
                                  text-gray-700
                                  bg-white bg-clip-padding bg-no-repeat
                                  border border-solid border-gray-300
                                  transition
                                  ease-in-out
                                  m-0
                                  focus:outline-none rounded-lg
                                  focus:text-gray-700 focus:bg-white focus:border-black "
                    aria-label="Default select example"
                    value={selectclient}
                    onChange={(e) => setSelectClient(e.target.value)}
                  >
                    <option value="">Select Client</option>

                    {redmine?.clients &&
                      redmine.clients.map((e, i) => (
                        <option key={i} value={e.value}>
                          {e.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="w-1/2">
                <div className="mb-3">
                  <label
                    htmlFor="heading"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Ticket Title
                  </label>
                  <input
                    type="text"
                    name="heading"
                    id="heading"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full px-2.5 py-3 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                    placeholder="Type Here..."
                    required
                    onChange={(e) => {
                      setNewTicket({
                        ...newTicket,
                        subject: e.target.value,
                      });
                    }}
                    value={newTicket.subject}
                  />
                </div>
              </div>
            </div>
            <div className="w-full pt-2">
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
              >
                Add Description
              </label>
              <textarea
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                id="exampleFormControlTextarea1"
                rows="5"
                placeholder="Type Here..."
                onChange={(e) => {
                  setNewTicket({
                    ...newTicket,
                    description: e.target.value,
                  });
                }}
                value={newTicket.description}
              ></textarea>
            </div>
            <div className="w-full pt-6 flex justify-end">
              <button
                className=" border-none bg-cyan-500 text-white py-2 px-10 rounded-md"
                onClick={() => createTicket()}
              >
                Create
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
