import React from "react";
import { ThreeDots } from "react-loader-spinner";

export default function Loader() {
  return (
    <div className="flex justify-center items-center">
      <ThreeDots height="100" width="100" color="#007BFF" ariaLabel="loading" />
    </div>
  );
}
