import React, { useEffect, useState } from "react";
import { getAsigneeListService } from "../Services/redmineServices";
import Select from "react-select";

export default function AsigneeDropdown({
  selectedOption,
  setSelectedOption,
  disabled,
}) {
  const initialState = {
    limit: 5,
    page: 1,
    data: [],
  };
  const [state, setState] = useState(initialState);
  const [isLoading, setisLoading] = useState(true);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const loadOptions = async (more = false) => {
    setisLoading(true);
    let page = state.page;
    if (more) {
      page++;
    }

    const data = await getAsigneeListService(page);
    console.log(data);

    if (data.status === 200) {
      const formatedData = data.data.map((e) => ({
        ...e,
        value: e.id,
        label: e.name,
      }));
      setState((prevState) => ({
        ...prevState,
        page: formatedData.length > 0 ? page : prevState.page,
        data: more ? prevState.data.concat(formatedData) : formatedData,
      }));
    }
    setisLoading(false);
  };

  useEffect(() => {
    setState(initialState);
    loadOptions();
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: disabled ? "gray" : "#000000",
      minHeight: "46px",
      height: "46px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: 8,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "46px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "46px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 231,
      padding: 0,
    }),
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={state.data}
      isSearchable={false}
      onMenuScrollToBottom={() => loadOptions(true)}
      isLoading={isLoading}
      loadingMessage={() => "Loading Please wait.."}
      styles={customStyles}
      isDisabled={disabled}
      className="w-full"
    />
  );
}
