import { v4 as uuidv4 } from "uuid";
/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep = (x, y) => {
  const firstKeys = Object.keys(x);
  Object.keys(y).map((e) => {
    if (firstKeys.includes(e)) {
      x[e] = x[e].concat(y[e]);
    } else {
      x[e] = y[e];
    }
  });
  return x;
};

export const uid = () => {
  return uuidv4();
};

export const formatTime = (time /* secs */) => {
  if (time && time != null && time !== undefined) {
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = ~~time % 60;

    return (
      (hrs < 10 ? "0" : "") +
      hrs +
      ":" +
      (mins < 10 ? "0" : "") +
      mins +
      ":" +
      (secs < 10 ? "0" : "") +
      secs
    );
  } else {
    return "00:00:00";
  }
};

export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};
