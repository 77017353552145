import { PlusCircleIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { handleScroll } from "../Helper/ScrollHelper";
import {
  getLeadStatusService,
  searchLeadService,
} from "../Services/leadsServices";
import AddLeadsCallPage from "./AddLeadsCallPage";
import Loader from "./Loader";
import ShowLeadDetailsCallPage from "./ShowLeadDetailsCallPage";

function Wapper(props) {
  return (
    <div className="w-full flex">
      <div
        className="w-full mr-2 p-5 rounded-md shadow-md bg-white overflow-auto"
        style={{ minHeight: 400 }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default function LeadsCallPage({}) {
  const [showState, setShowState] = useState("LIST"); //LIST / DETAILS / ADD /
  const [loading, setLoading] = useState(false);
  const initialLeadState = {
    data: [],
    page: 1,
    limit: 5,
    more_records: false,
    isSearch: false,
  };
  const [leadData, setLeadData] = useState(initialLeadState);
  const [leadStatus, setLeadStatus] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [cuLead, setCuLead] = useState({});
  const [selectedType, setSelectedType] = useState("");

  const dataType = {
    "": { name: "", field: "none", type: "text" },
    first_name: { name: "First Name", field: "first_name", type: "text" },
    phone_no: { name: "Phone No", field: "phone_no", type: "number" },
  };

  useEffect(() => {
    getLeadStatus();
  }, []);

  useEffect(() => {
    setSearchParam("");
    setLeadData(initialLeadState);
  }, [selectedType]);

  const getLeadStatus = async () => {
    try {
      const res = await getLeadStatusService();
      console.log("res:getLeadStatus", res);
      if (res.status === 200) {
        setLeadStatus(res.data.leadStatus);
      }
    } catch (error) {
      console.log("error:getLeadStatus", error);
    } finally {
      setLoading(false);
    }
  };
  const getLeadData = async (loadMore = false) => {
    try {
      if (
        searchParam.length === 0 ||
        (loadMore && leadData.more_records === false)
      ) {
        return;
      }
      if (!loadMore) {
        setLeadData(initialLeadState);
      }

      setLoading(true);
      const type = dataType[selectedType].field;
      const page = loadMore ? leadData.page + 1 : leadData.page;
      const res = await searchLeadService(searchParam, type, page);
      const leads = res.data.data;
      console.log("res:getLeadData", res);
      if (res.status === 200) {
        setLeadData((prevState) => ({
          data: loadMore ? prevState.data.concat(leads) : leads,
          page: leads.length > 0 ? page : prevState.page,
          limit: res.data.limit,
          more_records: res.data.more_records,
          isSearch: true,
        }));
      }
    } catch (error) {
      console.log("error:getLeadData", error);
    } finally {
      setLoading(false);
    }
  };

  if (showState === "LIST") {
    return (
      <Wapper>
        <>
          <div className="flex justify-between items-center">
            <p className="font-medium text-lg">Lead History</p>
            <p
              className="flex cursor-pointer items-center"
              onClick={() => setShowState("ADD")}
            >
              Add Lead
              <PlusCircleIcon className="ml-1 h-5 w-5 text-cyan-600" />
            </p>
          </div>
          <div className="w-full flex pt-4">
            <div className="w-2/6 mr-4">
              <div className="mb-3">
                <label
                  htmlFor="heading"
                  className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
                >
                  Select Type
                </label>
                <select
                  className="form-select appearance-none
                                  block
                                  w-full
                                  px-3
                                  py-2.5
                                  text-base
                                  font-normal
                                  text-gray-700
                                  bg-white bg-clip-padding bg-no-repeat
                                  border border-solid border-gray-300
                                  transition
                                  ease-in-out
                                  m-0
                                  focus:outline-none rounded-lg
                                  focus:text-gray-700 focus:bg-white focus:border-black "
                  aria-label="Select Type"
                  value={selectedType ?? ""}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  <option value={""}>Please Select Type</option>
                  <option value={"first_name"}>First Name</option>
                  <option value={"phone_no"}>Phone No</option>
                </select>
              </div>
            </div>
            <div className="w-1/2 mr-4">
              <div className="mb-3">
                <label
                  htmlFor="heading"
                  className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
                >
                  Search Lead By {dataType[selectedType].name}
                </label>
                <input
                  type={dataType[selectedType].type}
                  name="searchLead"
                  id="searchLead"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full px-2.5 py-3 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                  placeholder="Type Here..."
                  value={searchParam}
                  onChange={(e) => setSearchParam(e.target.value)}
                  disabled={selectedType === "" ? true : false}
                />
              </div>
            </div>
            <div className="w-1/6 flex items-center">
              <button
                className={
                  searchParam.length === 0
                    ? "border-none bg-gray-500 text-white py-2.5 mt-4 w-full rounded-sm mr-3"
                    : "border-none bg-cyan-500 text-white py-2.5 mt-4 w-full rounded-sm mr-3"
                }
                onClick={() => getLeadData()}
                disabled={searchParam.length === 0}
              >
                Search
              </button>
            </div>
          </div>
          <div className="w-full border border-gray-300"></div>
          <div className="w-full">
            <p className=" text-xs py-4">Search result</p>
          </div>
          <div
            className="w-full overflow-auto"
            style={{ height: 300 }}
            onScroll={(e) => handleScroll(e, () => getLeadData(true))}
          >
            <div>
              {leadData.data.length > 0 &&
                leadData.data.map((e, i) => (
                  <div
                    key={i}
                    className="flex mb-4"
                    onClick={() => {
                      setCuLead(e);
                      setShowState("DETAILS");
                    }}
                  >
                    {/* <div className="mr-3">{i + 1}</div> */}
                    <div className="w-full flex border border-gray-300 pt-4 pb-3 px-4 rounded-md cursor-pointer hover:hover:bg-slate-200">
                      <div className="w-1/6 overflow-auto pl-2">
                        <p className=" text-xs text-gray-600">First Name</p>
                        <span className="text-gray-800 text-sm">
                          {e?.First_Name}
                        </span>
                      </div>
                      <div className="w-1/6 overflow-auto pl-2">
                        <p className=" text-xs text-gray-600">Last Name</p>
                        <span className="text-gray-800 text-sm">
                          {e?.Last_Name}
                        </span>
                      </div>
                      <div className="w-1/6 overflow-auto pl-2">
                        <p className=" text-xs text-gray-600">Phone</p>
                        <span className="text-gray-800 text-sm">
                          {e?.Phone}
                        </span>
                      </div>
                      <div className="w-1/6 overflow-auto pl-2">
                        <p className=" text-xs text-gray-600">Email</p>
                        <span className="text-gray-800 text-sm">
                          {e?.Email}
                        </span>
                      </div>
                      <div className="w-1/6 overflow-auto pl-2">
                        <p className=" text-xs text-gray-600">Lead Owner</p>
                        <span className="text-gray-800 text-sm">
                          {e?.Lead_Owner}
                        </span>
                      </div>
                      <div className="w-1/6 overflow-auto pl-2">
                        <p className=" text-xs text-gray-600">Lead Status</p>
                        <span className="text-gray-800 text-sm">
                          {e?.Lead_Status ?? "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              {leadData.data.length === 0 && leadData.isSearch && !loading && (
                <div className="mr-3">No Lead Found</div>
              )}
              {loading && <Loader />}
            </div>
          </div>
        </>
      </Wapper>
    );
  } else if (showState === "DETAILS") {
    return (
      <Wapper>
        <ShowLeadDetailsCallPage
          backToList={() => setShowState("LIST")}
          leadStatus={leadStatus}
          cuLead={cuLead}
          setCuLead={(e) => setCuLead(e)}
        />
      </Wapper>
    );
  } else if (showState === "ADD") {
    return (
      <Wapper>
        <AddLeadsCallPage closeFrom={() => setShowState("LIST")} />
      </Wapper>
    );
  }
}
