import React, { useState } from "react";
import { addLeadService } from "../Services/leadsServices";
import { showError, showSuccess } from "../Helper/Tost";

export default function AddLeadsCallPage({ closeFrom }) {
  const defaultState = {
    Last_Name: "",
    First_Name: "",
    Email: "",
    Phone_Number: "",
    Company: "",
    Lead_Owner: "",
    Lead_Source: "",
    Description: "",
    Website: "",
  };
  const [state, setState] = useState(defaultState);

  const setData = (type, value) => {
    setState((prevState) => {
      return { ...prevState, [type]: value };
    });
  };

  const submitData = async () => {
    try {
      const res = await addLeadService(state);
      console.log("res******", res);
      if (res.status === 200) {
        showSuccess(res.data.msg);
        setState(defaultState);
        closeFrom();
      } else {
        showError(res.ErrorDescription);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  return (
    <div>
      <div className="w-full flex">
        <p className="font-medium text-lg">Add Lead</p>
      </div>
      <div className="w-full flex pt-4">
        <div className="w-1/2 mr-4">
          <div className="mb-3">
            <label
              htmlFor="First_Name"
              className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
            >
              First Name
            </label>
            <input
              type="text"
              id="First_Name"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full px-2.5 py-3 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              placeholder="Type Here..."
              required
              name="First_Name"
              value={state.First_Name}
              onChange={(e) => setData("First_Name", e.target.value)}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="mb-3">
            <label
              htmlFor="Last_Name"
              className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
            >
              Last Name
            </label>
            <input
              type="text"
              id="Last_Name"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full px-2.5 py-3 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              placeholder="Type Here..."
              required
              name="Last_Name"
              value={state.Last_Name}
              onChange={(e) => setData("Last_Name", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex pt-3">
        <div className="w-1/2 mr-4">
          <div className="mb-3">
            <label
              htmlFor="Phone_Number"
              className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
            >
              Phone
            </label>
            <input
              type="number"
              id="Phone_Number"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full px-2.5 py-3 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              placeholder="Type Here..."
              required
              name="Phone_Number"
              value={state.Phone_Number}
              onChange={(e) => setData("Phone_Number", e.target.value)}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="mb-3">
            <label
              htmlFor="Email"
              className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
            >
              Email
            </label>
            <input
              type="email"
              id="Email"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full px-2.5 py-3 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              placeholder="Type Here..."
              required
              name="Email"
              value={state.Email}
              onChange={(e) => setData("Email", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex pt-3">
        <div className="w-1/2 mr-4">
          <div className="mb-3">
            <label
              htmlFor="Lead_Owner"
              className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
            >
              Lead Owner
            </label>
            <input
              type="text"
              id="Lead_Owner"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full px-2.5 py-3 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              placeholder="Type Here..."
              required
              name="Lead_Owner"
              value={state.Lead_Owner}
              onChange={(e) => setData("Lead_Owner", e.target.value)}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="mb-3">
            <label
              htmlFor="Lead_Source"
              className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
            >
              Lead Source
            </label>
            <input
              type="text"
              id="Lead_Source"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full px-2.5 py-3 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              placeholder="Type Here..."
              required
              name="Lead_Source"
              value={state.Lead_Source}
              onChange={(e) => setData("Lead_Source", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex pt-3">
        <div className="w-1/2 mr-4">
          <div className="mb-3">
            <label
              htmlFor="Description"
              className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
            >
              Description
            </label>
            <textarea
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              id="Description"
              rows="6"
              placeholder="Type Here..."
              name="Description"
              value={state.Description}
              onChange={(e) => setData("Description", e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="w-1/2">
          <div className="w-full">
            <div className="mb-3">
              <label
                htmlFor="Website"
                className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
              >
                Website
              </label>
              <input
                type="text"
                id="Website"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full px-2.5 py-3 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                placeholder="Type Here..."
                required
                name="Website"
                value={state.Website}
                onChange={(e) => setData("Website", e.target.value)}
              />
            </div>
          </div>
          <div className="w-full pt-2">
            <div className="mb-3">
              <label
                htmlFor="Company"
                className="block mb-2 text-sm font-normal text-gray-900 dark:text-black"
              >
                Company
              </label>
              <input
                type="text"
                id="Company"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full px-2.5 py-3 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                placeholder="Type Here..."
                required
                name="Company"
                value={state.Company}
                onChange={(e) => setData("Company", e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex pt-3">
        <button
          className="border-none bg-cyan-500 text-white py-2 px-14 rounded-sm h-full mr-3"
          onClick={() => submitData()}
        >
          Save
        </button>
        <button
          className="border-none text-black py-2 px-14 rounded-md h-full"
          onClick={() => closeFrom()}
        >
          Close
        </button>
      </div>
    </div>
  );
}
