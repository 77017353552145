import { ArrowLeftIcon, ArrowPathIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React, { useState } from "react";
import { showError, showSuccess } from "../Helper/Tost";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import { updateLeadStatusService } from "../Services/leadsServices";
import Loader from "./Loader";

export default function ShowLeadDetailsCallPage({
  backToList,
  setCuLead,
  cuLead,
  leadStatus,
}) {
  // const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);

  const updateLeadStatus = async (param) => {
    try {
      setLoading(true);
      const payload = {
        id: cuLead.id,
        Lead_Status: param,
      };
      const res = await updateLeadStatusService(payload);
      console.log("res:updateLeadStatus", res);
      if (res.status === 200) {
        setCuLead((ps) => ({ ...ps, Lead_Status: param }));
        showSuccess(res.data.msg);
      } else {
        showError(res.ErrorDescription);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="w-full">
        <div
          className="flex"
          style={{ alignItems: "center" }}
          onClick={() => backToList()}
        >
          <ArrowLeftIcon className="w-4 h-4 text-cyan-600 mr-1  cursor-pointer" />
          <p className="text-sm  cursor-pointer">Back to Seach Result</p>
        </div>
      </div>
      <div className="w-full flex">
        <p className="text-md py-7">Lead Details</p>
      </div>
      <div className="w-full flex">
        <div className="w-full pr-5">
          <div className="w-full flex justify-between mb-4">
            <div>
              <p className=" text-sm text-gray-600">First Name</p>
              <span className="text-gray-800 text-md">
                {cuLead?.First_Name}
              </span>
            </div>
            <div className="text-right">
              <p className=" text-sm text-gray-600">Last Name</p>
              <span className="text-gray-800 text-md">{cuLead?.Last_Name}</span>
            </div>
          </div>
          <div className="w-full flex justify-between mb-4">
            <div>
              <p className=" text-sm text-gray-600">Email</p>
              <span className="text-gray-800 text-md">{cuLead?.Email}</span>
            </div>
            <div className="text-right">
              <p className=" text-sm text-gray-600">Phone</p>
              <span className="text-gray-800 text-md">{cuLead?.Phone}</span>
            </div>
          </div>

          <div className="w-full flex justify-between mb-4">
            <div>
              <p className="text-sm text-gray-600 mb-1">Lead Status</p>
              <select
                style={{ width: 174 }}
                className="form-select appearance-none
                                block
                                px-2
                                py-1
                                text-sm
                                font-normal
                                text-gray-800
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                value={cuLead?.Lead_Status}
                onChange={(e) => updateLeadStatus(e.target.value)}
              >
                <option value={""}>Please select Status</option>
                {leadStatus.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </select>
            </div>
            <div className="text-right">
              <p className=" text-sm text-gray-600">Lead Owner</p>
              <span className="text-gray-800 text-md">
                {cuLead?.Lead_Owner}
              </span>
            </div>
          </div>
          <div className="w-full flex justify-between mb-4">
            <div>
              <p className=" text-sm text-gray-600">Last Activity Time</p>
              <span className="text-gray-800 text-md">
                {cuLead?.Last_Activity_Time
                  ? moment(cuLead?.Last_Activity_Time).format("DD-MM-YYYY LT")
                  : "N/A"}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="w-1/2 pl-4">
          <p className="pb-4">History</p>
          <div className="w-full">
            <div className="pb-4">
              <p
                className="font-medium text-sm"
                style={{ fontSize: 13, paddingLeft: width * 0.018 }}
              >
                5 May 2022
              </p>
            </div>
            <div className="flex relative pb-8">
              <div className="w-20">
                <p className="font-light" style={{ fontSize: 10 }}>
                  11:01 AM
                </p>
              </div>

              <div className="h-4 rounded-full bg-white inline-flex items-center justify-center text-white relative z-10">
                <ArrowPathIcon className="w-4 h-4 text-cyan-600 mr-1 bg-white" />
                <div
                  className="h-full h-28 absolute pointer-events-none bg-cyan-100"
                  style={{ top: -17, zIndex: -1, width: 1.5, left: 7 }}
                />
              </div>
              <div className="flex-grow pl-3" style={{ width: 433 }}>
                <p className="text-sm font-light text-black pb-1">
                  Lead Status was updated from Advertisement to External
                  Referral
                </p>
                <p className="text-xs font-light">
                  by David David Sep 28, 2022
                </p>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="">
              <p className="ml-6 font-medium text-sm" style={{ fontSize: 13 }}>
                3 May 2022
              </p>
            </div>
            <div className="flex mb-12 pt-4 overflow-auto">
              <div className="w-20">
                <p className="font-light" style={{ fontSize: 10 }}>
                  11:01 AM
                </p>
              </div>
              <div className="h-4 rounded-full bg-white inline-flex items-center justify-center text-white relative z-10">
                <ArrowPathIcon className="w-4 h-4 text-cyan-600 mr-1 bg-white" />
                <div
                  className="h-full h-28 absolute pointer-events-none bg-cyan-100"
                  style={{ top: -17, zIndex: -1, width: 1.5, left: 7 }}
                />
              </div>
              <div className="flex-grow pl-3" style={{ width: 433 }}>
                <p className="text-sm font-light text-black pb-1 w-full">
                  Lead Created
                </p>
                <p className="text-xs font-light">
                  by David David Sep 28, 2022
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
