/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { FullLogo, GoogleImg } from "../Assets/Images";
import { useGoogleLogin } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { doLogin } from "../Redux/Actions/AuthActions";
import Loader from "../Components/Loader";

export default function Login(props) {
  const loading = useSelector((state) => state.Auth.loading);
  const dispatch = useDispatch();
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const onSuccess = (response) => {
    console.log("response", response);
    if (response.accessToken) {
      dispatch(
        doLogin({
          token: response.tokenId,
          accessToken: response.response,
          googleId: response.googleId,
          profileObj: response.profileObj,
          tokenObj: response.tokenObj,
        })
      );
    }
  };

  const onFailure = (error) => {
    console.log(error);
  };
  const { signIn, loaded } = useGoogleLogin({
    onSuccess,
    clientId,
    fetchBasicProfile: true,
    onFailure,
  });
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="main-page w-full h-screen relative"></div>
      <div className="main-page-overlay w-full h-screen absolute top-0 left-0"></div>
      <div className="sign-up rounded-lg w-1/4 absolute bg-white text-center">
        <div className="allcolor py-10">
          <img
            className=" table mx-auto"
            src={FullLogo}
            style={{ width: "320px" }}
          />
        </div>
        <div className="py-8">
          {loaded && (
            <button
              onClick={() => {
                signIn();
              }}
            >
              <div className="w-72 h-20 bg-blue-700 rounded-sm p-2 flex items-center">
                <div className="bg-white w-14 h-14 flex items-center justify-center mr-6">
                  <img src={GoogleImg} />
                </div>
                <div>
                  <p className="text-white text-lg">Sign In with Google</p>
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
