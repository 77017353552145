import React from "react";
import { MdEdit } from "react-icons/md";
import AddAgentToDo from "./AddAgentToDo";
import TodoList from "./TodoList";

export default function AgentTodoList({ agTodoList }) {
  return (
    <div className="w-1/2 ml-2">
      <AddAgentToDo />
      <div className="w-full px-5 py-4 rounded-md flex justify-between">
        <h4 className="text-xl">To-Do-List</h4>
        <MdEdit
          className=" cursor-pointer text-cyan-600 text-2xl"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalCenter"
        />
      </div>
      {agTodoList && agTodoList.length > 0 ? (
        <TodoList agTodoList={agTodoList} />
      ) : (
        <></>
      )}
    </div>
  );
}
