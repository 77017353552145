import React from "react";
import { BsHouse, BsHouseFill, BsTelephoneFill } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import { RiMessage2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import CommingSoonPopUp from "./CommingSoonPopUp";

export default function Sidebar() {
  return (
    <>
      <div className="sidebar w-28 h-full px-1">
        <ul className="relative">
          <li className="relative mt-14">
            <Link
              to={"/"}
              className="w-16 h-16 mx-auto flex rounded-xl items-center justify-center"
            >
              <BsHouseFill className="w-10 h-10 text-black mx-auto" />
            </Link>
          </li>
          <li className="relative mt-4">
            <Link
              to={"call"}
              className="w-16 h-16 mx-auto flex rounded-xl items-center justify-center"
            >
              <BsTelephoneFill className="w-9 h-9 text-black mx-auto" />
            </Link>
          </li>
          <li className="relative mt-4">
            <CommingSoonPopUp placement={"right"}>
              <Link
                to={"#"}
                className="w-16 h-16 mx-auto flex rounded-xl items-center justify-center "
              >
                <HiMail className="w-10 h-10 text-black mx-auto" />
              </Link>
            </CommingSoonPopUp>
          </li>

          <li className="relative mt-4">
            <CommingSoonPopUp placement={"right"}>
              <Link
                to={"#"}
                className="w-16 h-16 mx-auto flex rounded-xl items-center justify-center"
              >
                <RiMessage2Fill className="w-10 h-10 text-black mx-auto" />
              </Link>
            </CommingSoonPopUp>
          </li>
        </ul>
      </div>
    </>
  );
}
