import moment from "moment";
import API from "../Config/apiConfig";
import endpoints from "../Config/endpoints";
import { fotmatAgentData } from "../Helper/Agent";
import { getResponse } from "../Helper/APIResponseHelper/APIHelper";
import { formatTime } from "../Helper/Others";

const loadAgentActvityService = async (agentId, nextToken = "") => {
  try {
    const res = await API.post(
      endpoints.graphql_token,
      endpoints.graphql,
      JSON.stringify({
        query: `query MyQuery {
          getAgentActivity(agentId:"${agentId}", nextToken: "${nextToken}") {
            nextToken
            items {
              agentId
              agentName
              channel
              contactId
              contactStatus
              customer {
                email
                phone
              }
              customerId
              customerName
              customerPhone
              queueId
              recordingLink
              redmineNumber
              transcriptionLink
              timeStamp
              type
            }
          }
        }`,
      })
    );
    if (res.status === 200) {
      const data = res.data.data.getAgentActivity;
      const formatData = fotmatAgentData(data.items);
      return getResponse(res.status, {
        dataItems: data.items,
        data: formatData,
        nextToken: data.nextToken,
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    console.log("error:loadAgentActvityService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Agent ToDo List
 */
const loadTodoListService = async (agentId, nextToken = "") => {
  try {
    const res = await API.post(
      endpoints.graphql_token,
      endpoints.graphql,
      JSON.stringify({
        query: `query MyQuery {
            listAgentToDo(agentId:"${agentId}", nextToken:"${nextToken}") {
                ToDos {
                  agentId
                  id
                  notes
                  status
                  timestamp
                  title
                } 
                nextToken
              }
            }
          `,
      })
    );

    if (res.status === 200) {
      const data = res.data.data.listAgentToDo;
      return getResponse(res.status, {
        data: data.ToDos,
        nextToken: data.nextToken,
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Add Agent ToDo
 */
const addTodoListService = async (agentId, payload) => {
  try {
    const res = await API.post(
      endpoints.graphql_token,
      endpoints.graphql,
      JSON.stringify({
        query: `mutation MyMutation {
          addAgentToDo(agentId:"${agentId}", title: "${payload.title}",
                        notes: "${payload.notes}"
                      ) {
            agentId
            id
            notes
            status
            timestamp
            title
          }
        }`,
      })
    );

    if (res.status === 200) {
      return getResponse(res.status, {
        data: [],
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    console.log("error:loadTodoListService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Update Agent ToDo status
 */
const updateTodoListStatusService = async (agentId, id) => {
  try {
    const res = await API.post(
      endpoints.graphql_token,
      endpoints.graphql,
      JSON.stringify({
        query: `mutation MyMutation {
          updateAgentToDo(agentId:"${agentId}", id: "${id}", status: "done") {
            id
            notes
            status
            timestamp
            title
          }
        }`,
      })
    );
    console.log("updateTodoListStatusService:res", res);

    if (res.status === 200) {
      const data = res.data.data.updateAgentToDo;
      return getResponse(res.status, {
        data: data,
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    console.log("error:loadTodoListService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Update Agent ToDo
 */
const updateTodoListService = async (agentId, payload) => {
  try {
    const res = await API.post(
      endpoints.graphql_token,
      endpoints.graphql,
      JSON.stringify({
        query: `mutation MyMutation {
          updateAgentToDo(
            agentId: "${agentId}", id: "${payload.id}", title:"${payload.title}", notes:"${payload.notes}" 
          ) {
            id
            notes
            status
            timestamp
            title
          }
        }`,
      })
    );
    console.log("updateTodoListStatusService:res", res);

    if (res.status === 200) {
      const data = res.data.data.updateAgentToDo;
      return getResponse(res.status, {
        data: data,
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    console.log("error:loadTodoListService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * update Agent status
 */
const createAgentStatusService = async (agentId, startTime, status) => {
  try {
    const res = await API.post(
      endpoints.graphql_token,
      endpoints.graphql,
      JSON.stringify({
        query: `mutation MyMutation {
            createAgentStatus(agentId:"${agentId}",startTimeStamp:"${startTime}",status: "${status.toLowerCase()}"
          ) {
              agentId
              startTimeStamp
              status
            }
          }`,
      })
    );
    console.log("createAgentStatusService:res", res);

    if (res.status === 200 && !res.data.hasOwnProperty("errors")) {
      return getResponse(res.status, {
        data: {},
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    console.log("error:createAgentStatusService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * update Agent status
 */
const updateAgentStatusService = async (payload) => {
  try {
    const res = await API.post(
      endpoints.graphql_token,
      endpoints.graphql,
      JSON.stringify({
        query: `mutation MyMutation {
          updateAgentStatus(
            agentId:"${payload.agentId}",startTimeStamp:"${
          payload.cuTime
        }", status: "${payload.cuStatus.toLowerCase()}", 
            previousTimeStamp:"${
              payload.oldTime
            }", previousStatus:"${payload.oldStatus.toLowerCase()}") 
          {
            agentId
            startTimeStamp
            status
          }
        }`,
      })
    );
    console.log("updateAgentStatusService:res", res);
    if (res.status === 200 && !res.data.hasOwnProperty("errors")) {
      return getResponse(res.status, {
        data: {},
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    console.log("error:updateAgentStatusService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Get Agent Scripts
 */
const agentlistScriptsService = async (queueId, nextToken = "") => {
  try {
    const res = await API.post(
      endpoints.graphql_token,
      endpoints.graphql,
      JSON.stringify({
        query: `query MyQuery {
          listAgentScript(queueId: "${queueId}", nextToken:"${nextToken}") {
            Scripts {
              queueId
              queueName
              script
              scriptId
              timeStamp
              title   
            }
            nextToken
          }
        }`,
      })
    );
    console.log("agentlistScriptsService:res", res);

    if (res.status === 200) {
      const data = res.data.data.listAgentScript;
      return getResponse(res.status, {
        data: data.Scripts,
        nextToken: data.nextToken,
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    console.log("error:agentlistScriptsService", error);
    return getResponse(error.status, null, error.data?.errors);
  }
};

/**
 * Get contact History By customerId
 */
const getHistoryBycustomerIdService = async (id, nextToken = "") => {
  try {
    const res = await API.get(
      endpoints.restapi_token,
      endpoints.customerHistoryById + id + "?limit=40&nextToken=" + nextToken
    );
    console.log("getHistoryBycustomerIdService", res);

    if (res.status === 200) {
      const data = res.data.History;
      return getResponse(res.status, {
        data: data,
        nextToken: data.nextToken ?? "null",
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:getHistoryBycustomerIdService", error);
    return getResponse(error.status, null, error.data?.errors);
  }
};

/**
 * Get agent dashboard matrix
 */
const getAgentDashboardMatrixService = async (agentId) => {
  try {
    const res = await API.post(
      endpoints.graphql_token,
      endpoints.graphql,
      JSON.stringify({
        query: `query MyQuery {
          getMetrics(agentId: "${agentId}", date: "${moment().format(
          "YYYY-MM-DD"
        )}") {
            available
            offline
            pcw
            idle
            break
            busy
          }
        }
        `,
      })
    );
    console.log("getAgentDashboardMatrixService:res", res);

    if (res.status === 200) {
      const data = res.data.data.getMetrics;
      Object.keys(data).map((e) => {
        data[e] = formatTime(data[e]);
      });
      return getResponse(res.status, {
        data: data,
      });
    } else {
      return getResponse(res.status, null, res?.data?.errors);
    }
  } catch (error) {
    console.log("error:getAgentDashboardMatrixService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Agent Journey
 */
const createAgentJourneyService = async (payload) => {
  try {
    console.log("createAgentJourneyService*", payload);

    const res = await API.post(
      endpoints.restapi_token,
      endpoints.contactHistory,
      JSON.stringify(payload)
    );
    console.log("createAgentJourneyService:res", res);

    if (res.status === 200 && !res.data.hasOwnProperty("errors")) {
      return getResponse(res.status, {
        data: {},
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    console.log("error:createAgentJourneyService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * send Agent Profile Details
 */
const sendAgentProfileDetails = async (payload) => {
  try {
    console.log("sendAgentProfileDetails", payload);
    const res = await API.post(
      endpoints.restapi_token,
      endpoints.agentsProfile,
      JSON.stringify(payload)
    );
    console.log("sendAgentProfileDetails:res", res);

    if (res.status === 200) {
      return getResponse(res.status, {
        data: "",
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    console.log("error:sendAgentProfileDetails", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

export {
  loadAgentActvityService,
  loadTodoListService,
  addTodoListService,
  updateTodoListStatusService,
  createAgentStatusService,
  updateAgentStatusService,
  agentlistScriptsService,
  getHistoryBycustomerIdService,
  getAgentDashboardMatrixService,
  createAgentJourneyService,
  sendAgentProfileDetails,
  updateTodoListService,
};
