import API from "../Config/apiConfig";
import endpoints from "../Config/endpoints";
import { getResponse } from "../Helper/APIResponseHelper/APIHelper";
/**
 * Get Customer Details By Mob Service
 */
const getCustomerDetailsByMobService = async (mob) => {
  try {
    const res = await API.get(
      endpoints.restapi_token,
      endpoints.customerDetailsByMob + mob
    );
    console.log("getCustomerDetailsByMobService:res", res);

    if (res.status === 200) {
      const data = res.data;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:getCustomerDetailsByMobService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * createFirstTimeCustomerService
 */
const createFirstTimeCustomerService = async (payload) => {
  try {
    const res = await API.post(
      endpoints.graphql_token,
      endpoints.graphql,
      JSON.stringify({
        query: `
          mutation MyMutation {
            addCustomerData(
              client: "${payload.client}", 
              email: "${payload.email}", 
              firstName: "${payload.firstName}", 
              lastName: "${payload.lastName}", 
              phone: "${payload.phone}"
            ) {
              customerId
            }
          }
        `,
      })
    );
    console.log("createFirstTimeCustomerService:res", res);

    if (res.status === 200 && !res.data.hasOwnProperty("errors")) {
      return getResponse(res.status, {
        data: {},
      });
    } else {
      return getResponse(res.status, null, res.data.errors);
    }
  } catch (error) {
    console.log("error:createFirstTimeCustomerService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Get Audio Transcript Array
 */
const loadTranscriptDataService = async (url) => {
  try {
    console.log("url", url);
    const res = await API.get(null, url);
    console.log("loadTranscriptDataService:res", res);
    if (res.status === 200) {
      const data = res.data;
      return getResponse(res.status, {
        data,
      });
    } else {
      return { status: 400, msg: "Unavailable" };
    }
  } catch (error) {
    console.log("error:loadTranscriptDataService", error);
    return { status: 400, msg: "Unavailable" };
  }
};
export {
  getCustomerDetailsByMobService,
  createFirstTimeCustomerService,
  loadTranscriptDataService,
};
