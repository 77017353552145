import React from "react";
import moment from "moment";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { BsHeadphones } from "react-icons/bs";
import { FaTicketAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { handleScroll } from "../Helper/ScrollHelper";
import { getHistoryBycustomerId } from "../Redux/Actions";

export default function ContactHistoryList({ data = [] }) {
  const dispatch = useDispatch();

  return (
    <div
      className="w-full overflow-auto"
      style={{ height: 500 }}
      onScroll={(e) =>
        handleScroll(e, () => dispatch(getHistoryBycustomerId(true)))
      }
    >
      <ul>
        {data.map((e, i) => {
          return (
            <li
              className="flex justify-between bg-white px-5 py-5 rounded-md mb-4 shadow-md"
              key={i}
            >
              <div className="form-check w-full">
                <div className="flex w-full justify-between">
                  <h4>{e.title === "N/A" ? "" : e.title}</h4>
                  <span className="text-sm underline">
                    {moment(e.timeStamp).format("MMM-DD-YYYY [at] LT")}
                  </span>
                </div>
                <p className="text-sm mt-3">
                  {e.description === "N/A"
                    ? "Redmine Ticket Unavailable"
                    : e.description}
                </p>
                <div className="w-full flex mt-3">
                  <span className="text-blue-500 cursor-pointer flex items-center text-sm mr-6">
                    <BsHeadphones className="mr-1" />
                    <p className="text-gray-500">
                      Agent :
                      <span className="text-black ml-1">{e.agentName}</span>
                    </p>
                  </span>
                  <span className="text-blue-500 cursor-pointer flex items-center text-sm mr-6">
                    <AiOutlineMenuUnfold className="mr-1" />
                    <p className="text-gray-500">
                      Queue :
                      <span className="text-black ml-1">{e.queueName}</span>
                    </p>
                  </span>
                  <span className="text-blue-500 cursor-pointer flex items-center text-sm mr-6">
                    <FaTicketAlt className="mr-1" />
                    <p className="text-gray-500">
                      Ticket :
                      <span className="text-blue-500 ml-1 underline">
                        {e.ticketUrl !== "N/A" ? (
                          <a
                            href={e.ticketUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            #{e.redmineNumber}
                          </a>
                        ) : (
                          <span>#{e.redmineNumber}</span>
                        )}
                      </span>
                    </p>
                  </span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
