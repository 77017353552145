import moment from "moment";
import { InitCCP, CCPLogout } from "../../Helper/CCPHelper";
import { createAgentStatusService } from "../../Services/agentServices";
import {
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  LOGOUT,
  UPDATE_AUTH_STATE,
  SET_AGENT_CONFIG,
} from "../actionTypes";
import {
  getAgentDashboardMatrix,
  setAgentConfig,
  updateAgentStatusRepeat,
} from "./AgentActions";
import { setContactEvents } from "./CallActions";
import { getByClientName, getProjectList } from "./RedmineActions";

const doLogin = (data) => {
  return async (dispatch, getState) => {
    dispatch(loadingStatus(true));
    dispatch({
      type: LOGIN_SUCCESS,
      data,
    });
    await InitCCP();
    await dispatch(setAgentConfig());
    dispatch(getProjectList());
    dispatch(getByClientName());
    dispatch(setContactEvents());
    dispatch({
      type: LOGIN_SUCCESS,
      data: { isCppLoaded: true },
    });
    dispatch(loadingStatus(false));
    dispatch(createAgentStatusAndUpdateTime());
    dispatch(getAgentDashboardMatrix());
  };
};

const createAgentStatusAndUpdateTime = () => {
  return async (dispatch, getState) => {
    const agentRD = getState()?.Agent;
    const time = moment().toISOString();
    await createAgentStatusService(
      agentRD.agentId,
      time,
      agentRD.agentCuStatusName
    );
    dispatch({
      type: SET_AGENT_CONFIG,
      data: {
        agentCuStatusStartTime: time,
      },
    });
  };
};

const LogOut = () => {
  return async (dispatch, getState) => {
    dispatch(loadingStatus(true));
    dispatch(updateAgentStatusRepeat());
    await CCPLogout();
    dispatch(loadingStatus(false));
    dispatch({
      type: LOGOUT,
    });
    localStorage.removeItem("root");
    localStorage.clear();
  };
};

const loadingStatus = (loading) => {
  return {
    type: LOGIN_LOADING,
    data: { loading },
  };
};

const UpdateAuthState = (data) => {
  return {
    type: UPDATE_AUTH_STATE,
    data: data,
  };
};
export { loadingStatus, LogOut, UpdateAuthState, doLogin };
