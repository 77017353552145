import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-tailwind/react";
import Loader from "./Components/Loader";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <ThemeProvider>
            <Router />
          </ThemeProvider>
          <ToastContainer />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
