import React, { useEffect, useState } from "react";
import {
  AvailableImg,
  IdleImg,
  CallImg,
  AcwImg,
  BreakImg,
} from "../../Assets/Images";
import AgentActivityList from "../../Components/AgentActivityList";
import AgentTodoList from "../../Components/AgentTodoList";
import { useDispatch, useSelector } from "react-redux";
import DashBoardDetails from "../../Components/DashBoardDetails";
import { getTicketsByIDService } from "../../Services/redmineServices";
import {
  getAgentActivity,
  getAgentDashboardMatrix,
  getAgentTodoList,
  updateAgentStatusRepeat,
} from "../../Redux/Actions";
import { showError } from "../../Helper/Tost";
import Loader from "../../Components/Loader";

export default function Index() {
  const dispatch = useDispatch();
  const agent = useSelector((state) => state.Agent);
  const redmine = useSelector((state) => state.Redmine);
  const [sdActivity, setSdActivity] = useState(null);
  const [cuTicket, setcuTicket] = useState(null);
  const [actLoading, setActLoading] = useState(false);

  useEffect(() => {
    dispatch(getAgentActivity());
    dispatch(getAgentTodoList());
    const dbMatrix = setInterval(() => {
      dispatch(updateAgentStatusRepeat());
    }, 60000);
    return () => {
      clearInterval(dbMatrix);
    };
  }, []);

  const onSelectActivity = async (param) => {
    try {
      console.log(param);
      setActLoading(true);
      if (param.redmineNumber != "N/A") {
        const res = await getTicketsByIDService(param.redmineNumber);
        if (res.status === 200 && !res.data.hasOwnProperty("msg")) {
          setcuTicket(res.data);
        } else {
          showError(res.data.msg);
        }
      }
    } catch (error) {
      console.log("error", error);
      showError("Unable to load details.");
    } finally {
      setTimeout(() => {
        setSdActivity(Object.assign({}, param));
        setActLoading(false);
      }, 1000);
    }
  };
  const { agDBMatrix } = agent;
  return (
    <>
      <div className="full-body w-full bg-gray-50 ">
        <>
          <div className="flex pl-5 pr-24 pt-8">
            <div className="w-1/5 bg-white h-auto shadow px-5 py-4 rounded-md mr-5 ">
              <img src={AvailableImg} className="imgopacity absolute" alt="" />
              <div className="flex items-center justify-center flex-col">
                <p className="text-lg mb-2">Available</p>
                <h3 className="font-bold text-green-600 text-3xl">
                  {agDBMatrix?.available}
                </h3>
              </div>
            </div>
            <div className="w-1/5 idle bg-white h-auto shadow px-5 py-4 rounded-md mr-5 ">
              <img src={IdleImg} className="imgopacity absolute" alt="" />
              <div className="flex items-center justify-center flex-col">
                <p className="text-lg mb-2">Idle</p>
                <h3 className="font-bold text-amber-400 text-3xl">
                  {agDBMatrix?.idle}
                </h3>
              </div>
            </div>
            <div className="w-1/5 call bg-white h-auto shadow px-5 py-4 rounded-md mr-5 ">
              <img src={CallImg} className="imgopacity absolute" alt="" />
              <div className="flex items-center justify-center flex-col">
                <p className="text-lg mb-2">Call Time</p>
                <h3 className="font-bold text-indigo-500 text-3xl">
                  {agDBMatrix?.busy}
                </h3>
              </div>
            </div>
            <div className="w-1/5 acw bg-white h-auto shadow px-5 py-4 rounded-md mr-5 ">
              <img src={AcwImg} className="imgopacity absolute" alt="" />
              <div className="flex items-center justify-center flex-col">
                <p
                  className="text-lg mb-2 ml-10"
                  style={{ letterSpacing: -0.5 }}
                >
                  Post Call Work
                  <span style={{ fontSize: "60%" }}> (PCW)</span>
                </p>
                <h3 className="font-bold text-blue-500 text-3xl">
                  {agDBMatrix?.pcw}
                </h3>
              </div>
            </div>
            <div className="w-1/5 break bg-white h-auto shadow px-5 py-4 rounded-md ">
              <img src={BreakImg} className="imgopacity absolute" alt="" />
              <div className="flex items-center justify-center flex-col">
                <p className="text-lg mb-2">Break</p>
                <h3 className="font-bold text-cyan-500 text-3xl">
                  {agDBMatrix?.break}
                </h3>
              </div>
            </div>
          </div>

          <div className="flex pl-5 pr-24 pt-8 bg-gray-50">
            <AgentActivityList
              agActivity={agent?.agActivity}
              sdActivity={sdActivity}
              onSelectActivity={(e) => onSelectActivity(e)}
            />
            <AgentTodoList agTodoList={agent?.agTodoList} />
          </div>
          {actLoading ? (
            <Loader />
          ) : sdActivity != null ? (
            <DashBoardDetails
              redmine={redmine?.tickets}
              cuTicket={cuTicket}
              sdActivity={sdActivity}
              agent={agent}
            />
          ) : (
            <></>
          )}
          <div className="pl-5 pr-24 pt-8 bg-gray-50"></div>
        </>
      </div>
    </>
  );
}
