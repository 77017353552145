import API from "../Config/apiConfig";
import endpoints from "../Config/endpoints";
import { getResponse } from "../Helper/APIResponseHelper/APIHelper";

/**
 * Agent ToDo List
 */
const sendNotification = async (payload) => {
  try {
    const res = await API.post(
      endpoints.restapi_token,
      endpoints.ses,
      JSON.stringify(payload)
    );
    console.log("res:sendNotification", res);
    if (res.status === 200) {
      return getResponse(res.status, res.data);
    } else {
      return getResponse(res.status, null, res.data);
    }
  } catch (error) {
    console.log("error:sendNotification", error);
    return getResponse(error.status, null, error.data);
  }
};

export { sendNotification };
