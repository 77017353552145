import {
  SET_AGENT_CONFIG,
  GET_AGENT_STATUS,
  GET_AGENT_ACTIVITY,
  GET_AGENT_TODOLIST,
  UPDATE_AGENT_TODOLIST,
  GET_AGENT_SCRIPTSLIST,
  GET_CONTACT_HISTORYBY_ID,
  GET_AGENT_DASHBOARD_MATRIX,
  LOGOUT,
} from "../actionTypes";

const INITIAL_STATE = {
  loading: false,
  agentId: "",
  agentConfigCpp: {},
  agentConfig: {},
  agentStatus: [],
  agentCuStatusName: null,
  agentCuStatusStartTime: "",
  agentCuStatusIndex: "",
  agActivity: {},
  agActivityNextToken: "",
  agTodoList: [],
  agTodoListNextToken: "",
  agScriptList: [],
  agagScriptListToken: "",
  contactHistory: [],
  contactHistoryToken: "",
  agDBMatrix: null,
  queueType: "",
};

const AgentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AGENT_CONFIG:
      return {
        ...state,
        ...action.data,
      };
    case GET_AGENT_ACTIVITY:
      return {
        ...state,
        ...action.data,
      };
    case GET_AGENT_TODOLIST:
      return {
        ...state,
        ...action.data,
      };
    case UPDATE_AGENT_TODOLIST:
      return {
        ...state,
        ...action.data,
      };

    case GET_AGENT_STATUS:
      return {
        ...state,
        ...action.data,
      };

    case GET_AGENT_SCRIPTSLIST:
      return {
        ...state,
        ...action.data,
      };

    case GET_CONTACT_HISTORYBY_ID:
      return {
        ...state,
        ...action.data,
      };

    case GET_AGENT_DASHBOARD_MATRIX:
      return {
        ...state,
        ...action.data,
      };

    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default AgentReducer;
