import React from "react";
import { Accordion } from "react-bootstrap-accordion";
import "react-bootstrap-accordion/dist/index.css";

export default function AgentScriptList({ data = [] }) {
  return (
    <div className="accordion accordion-flush" id="accordionFlushExample">
      {data?.length > 0 ? (
        data.map((e, i) => {
          return (
            <div style={{ marginTop: 15 }}>
              <Accordion key={i} title={e?.title}>
                {e?.script}
              </Accordion>
            </div>
          );
        })
      ) : (
        <>Empty</>
      )}
    </div>
  );
}
