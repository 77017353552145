import React from "react";
import IncomingCallImg from ".././Assets/Images/incoming.png";
import AcceptCallImg from ".././Assets/Images/Accept.png";
import RejectCallImg from ".././Assets/Images/Reject.png";
import { useDispatch, useSelector } from "react-redux";
import { acceptCall, destroyCall } from "../Redux/Actions";
import { useNavigate } from "react-router-dom";

export default function IncomingCall() {
  const call = useSelector((state) => state.Call);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const acceptCallFN = () => {
    dispatch(acceptCall());
    navigate("call");
  };
  const destroyCallFN = () => {
    dispatch(destroyCall());
    navigate("call");
  };
  return (
    <>
      <button
        id="openOnCallBtn"
        type="button"
        class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        data-bs-toggle="modal"
        data-bs-target="#IncomingCall"
        style={{ display: "none" }}
      ></button>

      <button
        id="closeOnCallBtn"
        type="button"
        className="btn-close box-content w-4 h-4 p-1 text-cyan-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
        data-bs-dismiss="modal"
        data-bs-target="#IncomingCall"
        aria-label="Close"
        style={{ display: "none" }}
      ></button>
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="IncomingCall"
        tabindex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog w-1/4 modal-dialog-centered relative pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-clip-padding rounded-md outline-none text-current">
            <div
              className="modal-header flex flex-shrink-0 items-center justify-center py-7 px-6 rounded-t-md"
              style={{ background: "#85CC94" }}
            >
              <img
                src={IncomingCallImg}
                className="absolute w-24 left-4"
                alt=""
              />
              <div className="flex items-center justify-center flex-col">
                <p className="text-lg mb-4 text-white">Incoming Call</p>
                <h3 className="text-white text-2xl font-semibold">
                  {/* +1-3523452341 */}
                  {call?.phoneNumber}
                </h3>
              </div>
            </div>
            <div className="modal-body relative flex h-28">
              <button
                onClick={() => acceptCallFN()}
                className="w-1/2 flex justify-center items-center rounded-bl-md"
                style={{ background: "#117E26" }}
                data-bs-toggle="modal"
                data-bs-target="#IncomingCall"
              >
                <img
                  src={AcceptCallImg}
                  className="absolute w-16 left-4"
                  alt=""
                />
                <p className="text-lg text-white">Accept Call</p>
              </button>
              <button
                onClick={() => destroyCallFN()}
                className="w-1/2 flex justify-center items-center rounded-br-md"
                style={{ background: "#D0031C" }}
                data-bs-toggle="modal"
                data-bs-target="#IncomingCall"
              >
                <img
                  src={RejectCallImg}
                  className="absolute w-16"
                  alt=""
                  style={{ left: "57%" }}
                />
                <p className="text-lg text-white">Reject Call</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
