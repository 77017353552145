import {
  GET_PROJECT_LIST,
  GET_CLIENT_NAME,
  GET_TICKETS_BY_CLIENT_NAME,
} from "../actionTypes";
import {
  getClientNameService,
  getProjectService,
  getTicketsByClientNameService,
} from "../../Services/redmineServices";

const getProjectList = () => {
  return async (dispatch, getState) => {
    const res = await getProjectService();
    dispatch({
      type: GET_PROJECT_LIST,
      data: {
        projects: res.data,
      },
    });
  };
};

const getByClientName = () => {
  return async (dispatch, getState) => {
    const res = await getClientNameService();
    dispatch({
      type: GET_CLIENT_NAME,
      data: {
        clients: res.data,
      },
    });
  };
};

const getTicketsByClientName = (clientName) => {
  return async (dispatch, getState) => {
    const res = await getTicketsByClientNameService(clientName);
    dispatch({
      type: GET_TICKETS_BY_CLIENT_NAME,
      data: {
        tickets: res.data,
      },
    });
  };
};

export { getProjectList, getByClientName, getTicketsByClientName };
