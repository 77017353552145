import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const OutHook = {};

export const OutHookConfigurator = () => {
  const _useNavigate = useNavigate();

  useEffect(() => {
    OutHook.navigate = _useNavigate;
  }, [_useNavigate]);

  return null;
};

export default OutHook;
