import React from "react";
import Notification from "./Notification";
import CustomAudioPlayer from "./CustomAudioPlayer";
import { ShoWErrorMsg } from "./HelperComponent";
import AudioTranscript from "./AudioTranscript";

export default function DashBoardDetails({
  redmine,
  cuTicket,
  sdActivity,
  agent,
}) {
  return (
    <div className="pr-24 pt-8 bg-gray-50 pb-4">
      <div className="w-full  p-5 rounded-md">
        <ul
          className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none  pl-0 mb-4"
          id="tabs-tab3"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <a
              href="#tabs-home3"
              className="
                    nav-link
                    w-full
                    block
                    font-medium
                    text-xl
                    leading-tight
                    border-x-0 border-t-0 border-b-2 border-transparent
                    px-6
                    py-3
                    hover:border-transparent hover:bg-gray-100
                    focus:border-transparent
                    active
                  "
              id="tabs-home-tab3"
              data-bs-toggle="pill"
              data-bs-target="#tabs-home3"
              role="tab"
              aria-controls="tabs-home3"
              aria-selected="true"
            >
              Recordings
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#tabs-profile3"
              className="
                        nav-link
                        w-full
                        block
                        font-medium
                        text-xl
                        leading-tight
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                      "
              id="tabs-profile-tab3"
              data-bs-toggle="pill"
              data-bs-target="#tabs-profile3"
              role="tab"
              aria-controls="tabs-profile3"
              aria-selected="false"
            >
              Agile
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#tabs-messages3"
              className="
                        nav-link
                        w-full
                        block
                        font-medium
                        text-xl
                        leading-tight
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                      "
              id="tabs-messages-tab3"
              data-bs-toggle="pill"
              data-bs-target="#tabs-messages3"
              role="tab"
              aria-controls="tabs-messages3"
              aria-selected="false"
            >
              Notification
            </a>
          </li>
        </ul>
        {/* Audio and Call Transcript */}
        <div className="tab-content" id="tabs-tabContent3">
          <div
            className="flex tab-pane fade show active"
            id="tabs-home3"
            role="tabpanel"
            aria-labelledby="tabs-home-tab3"
          >
            <div className="w-full flex">
              {/* Audio */}
              <CustomAudioPlayer sdActivity={sdActivity} />

              {/* Call Transcript */}
              <AudioTranscript sdActivity={sdActivity} />
            </div>
          </div>
          {/* Agile Tickets */}
          <div
            className="tab-pane fade"
            id="tabs-profile3"
            role="tabpanel"
            aria-labelledby="tabs-profile-tab3"
          >
            <div className="w-full flex flex-wrap justify-between overflow-auto h-96">
              {cuTicket ? (
                <div
                  className="bg-white shadow-md px-5 py-3 rounded-md mb-4"
                  style={{ width: "49.5%", height: 150 }}
                >
                  <div className="w-full flex justify-between">
                    <div>
                      <h4 className="text-lg">{cuTicket.subject}</h4>
                      <p className="text-sm mt-3">{cuTicket.description}</p>
                    </div>
                    <div>
                      <a
                        className="text-md underline text-cyan-500"
                        target="_blank"
                        rel="noreferrer"
                        href={cuTicket.href}
                      >
                        #{cuTicket.id}
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <ShoWErrorMsg msg="No ticket associated to this call" />
              )}
            </div>
            <a
              href={process.env.REACT_APP_AGILE_URL}
              target="_blank"
              rel="noreferrer"
            >
              <p className="pt-5">
                For more ticket visit{" "}
                <span className="text-cyan-500 underline font-bold">
                  Redmine
                </span>
              </p>
            </a>
          </div>
          {/* Notification */}
          <div
            className="tab-pane fade"
            id="tabs-messages3"
            role="tabpanel"
            aria-labelledby="tabs-profile-tab3"
          >
            {sdActivity.redmineNumber && sdActivity.redmineNumber !== "N/A" ? (
              <Notification
                addClass="w-2/5"
                cuTicket={cuTicket}
                sdActivity={sdActivity}
                agent={agent}
              />
            ) : (
              <ShoWErrorMsg msg="No ticket associated to this call" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
