import moment from "moment";
import { uid } from "../Others";

/**
 * groupBy date
 */
const fotmatAgentData = (data) => {
  if (data.length == 0) {
    return {};
  }
  const fm = data.map((e) => {
    return {
      ...e,
      timeDate: moment(e.timeStamp).format("DD MMM YYYY"),
      timeHour: moment(e.timeStamp).format("h A"),
      uid: uid(),
    };
  });
  const groupByDate = fm.reduce(function (rv, x) {
    (rv[x["timeDate"]] = rv[x["timeDate"]] || []).push(x);
    return rv;
  }, {});
  return groupByDate;
};
const createOrUpdateAgentStatus = () => {};
export { fotmatAgentData, createOrUpdateAgentStatus };
