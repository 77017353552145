import React, { useEffect, useState } from "react";
import { getTicketsByClientNameService } from "../Services/redmineServices";
import Select from "react-select";

export default function TicketsDropdown({
  clientName,
  projectId,
  selectedOption,
  setSelectedOption,
  disabled,
}) {
  const initialState = {
    limit: 5,
    offset: 0,
    data: [],
  };
  const [state, setState] = useState(initialState);
  const [isLoading, setisLoading] = useState(true);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const loadOptions = async (more = false) => {
    setisLoading(true);
    let limit = state.limit;
    let offset = state.offset;
    if (more) {
      offset += limit;
    }

    const data = await getTicketsByClientNameService(
      clientName,
      projectId,
      limit,
      offset
    );
    console.log(data);

    if (data.status === 200) {
      const formatedData = data.data.map((e) => ({
        ...e,
        value: e.id,
        label: e.id + " - " + e.subject,
      }));
      setState((prevState) => ({
        ...prevState,
        offset: formatedData.length > 0 ? offset : prevState.offset,
        data: more ? prevState.data.concat(formatedData) : formatedData,
      }));
    }
    setisLoading(false);
  };

  useEffect(() => {
    setState(initialState);
    loadOptions();
  }, [clientName, projectId]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: disabled ? "gray" : "#000000",
      minHeight: "46px",
      height: "46px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: 8,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "46px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "46px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 350,
      padding: 0,
      fontSize: 15,
      letterSpacing: -0.8,
      lineHeight: "17px",
    }),
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={state.data}
      isSearchable={false}
      onMenuScrollToBottom={() => loadOptions(true)}
      isLoading={isLoading}
      loadingMessage={() => "Loading Please wait"}
      styles={customStyles}
      isDisabled={disabled}
    />
  );
}
