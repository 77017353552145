import {
  GET_CUSTOMER_DETAILS_BY_MOB,
  RESET_CALL_REDUCER,
  SET_CALL_CONTACT_DATA,
  SET_CALL_LOG,
  SET_MOB,
} from "../actionTypes";
import {
  createFirstTimeCustomerService,
  getCustomerDetailsByMobService,
} from "../../Services/callServices";
import { getTicketsByClientNameService } from "../../Services/redmineServices";
import { createAgentJourney, getHistoryBycustomerId } from "./AgentActions";
import { openOnCallBtnfn } from "../../Helper/CCPHelper";
import OutHook from "../../Components/OutHookConfigurator";
const connect = window.connect;

const getCustomerDetailsByMob = (phoneNumber = "") => {
  return async (dispatch, getState) => {
    const res = await getCustomerDetailsByMobService(phoneNumber);
    if (res.status === 200 && res?.data.hasOwnProperty("client")) {
      // get Tickets
      const ticketRes = await getTicketsByClientNameService(
        res.data.client,
        res.data.clientId
      );
      // get History
      dispatch(getHistoryBycustomerId(false, res.data.customerId));
      dispatch({
        type: GET_CUSTOMER_DETAILS_BY_MOB,
        data: {
          cuCustomer: res.data,
          tickets: ticketRes.data,
        },
      });
    }
  };
};

const setContactEvents = () => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    connect.contact(function (contact) {
      dispatch({
        type: SET_CALL_CONTACT_DATA,
        data: {
          contactState: contact,
        },
      });

      contact.onConnecting(async (res) => {
        const data = res.getActiveInitialConnection()?.getEndpoint();
        const { phoneNumber } = data;
        const phone = phoneNumber.replace(/[^0-9]/g, "");
        await dispatch({
          type: SET_MOB,
          data: {
            phoneNumber: phone,
          },
        });
        await dispatch(getCustomerDetailsByMob(phone));
        openOnCallBtnfn();
        const payload = {
          contactId: res.contactId,
          agentId: agent.agentId,
          agentName: agent.agentConfigCpp.name,
          channel: res.getType() === "voice" ? "call" : res.getType(),
          type: res.isInbound() ? "inbound" : "outbound",
          queueId: res.getQueue().queueId,
          activityOf: "agent",
          contactStatus: "N/A",
          activity: "N/A",
          redmineNumber: "N/A",
        };
        dispatch({
          type: SET_CALL_LOG,
          data: { cuCallLog: payload },
        });
        dispatch(createAgentJourney({ contactStatus: "callReceived" }));
      });

      contact.onAccepted(function (res) {
        dispatch({
          type: SET_CALL_LOG,
          data: { isCallAccepted: true },
        });

        dispatch(createAgentJourney({ activity: "callAnswered" }));
      });

      contact.onEnded(function (res) {
        const call = getState()?.Call;
        if (call.isCallAccepted && res.hasOwnProperty("contactData")) {
          dispatch(
            createAgentJourney({
              contactStatus: "callCompleted",
            })
          );
          dispatch({
            type: RESET_CALL_REDUCER,
          });
          OutHook.navigate("/");
        } else if (!res.hasOwnProperty("contactData")) {
          dispatch(createAgentJourney({ contactStatus: "callEnded" }));
        }
      });

      contact.onACW(function (res) {
        // dispatch(
        //   createAgentJourney({
        //     contactStatus: "postCallWork",
        //     activity: "postCallWork",
        //   })
        // );
      });

      contact.onDestroy(function (res) {
        const call = getState()?.Call;
        if (!call.isCallAccepted) {
          dispatch(createAgentJourney({ activity: "callDeclined" }));
          dispatch(
            createAgentJourney({
              contactStatus: "callCompleted",
            })
          );
          dispatch({
            type: RESET_CALL_REDUCER,
          });
          OutHook.navigate("/");
        }
      });

      // testing
      contact.onPending(function (res) {
        console.log("onPending **", res);
      });

      contact.onIncoming(function (res) {
        console.log("onIncoming **", res);
      });

      contact.onMissed(function (res) {
        console.log("onMissed **", res);
        // contact.clear();
        dispatch({
          type: RESET_CALL_REDUCER,
        });
      });

      contact.onConnected(function (res) {
        console.log("onConnected **", res);
      });
    });
  };
};

const acceptCall = () => {
  return async (dispatch, getState) => {
    const call = getState().Call;
    const { contactState } = call;
    contactState.accept();
  };
};

const destroyCall = () => {
  return async (dispatch, getState) => {
    const call = getState().Call;
    const { contactState } = call;

    contactState.reject();

    // contactState.clear();
    // const initialConnection = contactState.getInitialConnection();
    // const thirdParty = contactState.getSingleActiveThirdPartyConnection();
    // if (initialConnection) {
    //   initialConnection.destroy();
    // }
    // if (thirdParty) {
    //   thirdParty.destroy();
    // }
  };
};

// const callME = () => {
//   // const endpoint = connect.Endpoint.byPhoneNumber("+18005550100");
//   // const endpoint = connect.Endpoint.byPhoneNumber("+16692715737");
//   const endpoint = connect.Endpoint.byPhoneNumber("+19725461645");
//   const agent = new connect.Agent();

//   agent.connect(endpoint, {
//     // queueARN: queueArn,
//     success: function () {
//       console.log("outbound call connected");
//     },
//     failure: function (err) {
//       console.log("outbound call connection failed");
//       console.log(err);
//     },
//   });
//   // agent.setStatus
// };

const createFirstTimeCustomer = (payload) => {
  return async (dispatch, getState) => {
    const res = await createFirstTimeCustomerService(payload);
    if (res.status === 200) {
      dispatch(getCustomerDetailsByMob(payload.phone));
    }
  };
};

export {
  getCustomerDetailsByMob,
  setContactEvents,
  acceptCall,
  destroyCall,
  createFirstTimeCustomer,
};
