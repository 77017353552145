import React from "react";
import { useSelector } from "react-redux";

export default function AgentCCP() {
  const call = useSelector((state) => state.Call);
  return (
    <div className="w-1/3 bg-white py-5 px-6 shadow-md rounded-md mr-9 h-fit">
      {call?.cuCustomer && (
        <div className="w-full flex items-center">
          <div
            className="w-28 h-28"
            style={{
              backgroundColor: "#AB47BC",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 100,
            }}
          >
            <p
              style={{
                fontSize: 70,
                color: "#fff",
                lineHeight: 0.9,
              }}
            >
              {call?.cuCustomer?.firstName
                ? call?.cuCustomer?.firstName.substring(0, 1).toUpperCase()
                : ""}
            </p>
          </div>
          <div className="pl-10">
            <h3 className="text-lg font-bold">
              {(call?.cuCustomer?.firstName ?? "") +
                " " +
                (call?.cuCustomer?.lastName ?? "")}
            </h3>

            <p className="pt-2">{call?.phoneNumber}</p>
          </div>
        </div>
      )}

      <div
        className="w-full mt-6"
        id="new-container-div-1"
        style={{ height: 500 }}
      ></div>
      {/* <div className="w-full mt-6">
        <div className="w-full h-7 bg-black"></div>
        <img src={GroupImg} />
      </div>
      <div className="w-full px-3 pt-6 pb-2 bg-gray-100 flex ">
        <div className="w-1/2 bg-white py-2 flex items-center justify-center shadow-md mr-1">
          <BsFillMicMuteFill className="mr-3" />
          <p>Hold</p>
        </div>
        <div className="w-1/2 bg-white py-2 flex items-center justify-center shadow-md ml-1">
          <BsFillMicMuteFill className="mr-3" />
          <p>Mute</p>
        </div>
      </div>
      <div className="w-full px-3 pb-3 bg-gray-100 flex ">
        <div className="w-1/2 bg-white py-2 flex items-center justify-center shadow-md mr-1">
          <BiDialpadAlt className="mr-3" />
          <p>Dial number</p>
        </div>
        <div className="w-1/2 bg-white py-2 flex items-center justify-center shadow-md ml-1">
          <HiPhoneOutgoing className="mr-3" />
          <p>Mute</p>
        </div>
      </div>
      <div className="w-full bg-gray-100 pt-16 pb-4 px-3">
        <button
          className="w-full flex justify-center items-center text-white py-2"
          style={{ background: "#002633" }}
        >
          <HiPhoneOutgoing className="mr-3" />
          <p>Transfer Call</p>
        </button>
      </div> */}
    </div>
  );
}
