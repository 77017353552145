const connect = window.connect;
const InitCCP = () => {
  return new Promise((resolve, reject) => {
    try {
      const containerDiv = document.getElementById("container-div-1");
      const instanceURL = process.env.REACT_APP_AWS_CCP_INSTANCE_URL;

      connect.core.initCCP(containerDiv, {
        loginUrl: process.env.REACT_APP_AWS_CCP_LOGIN_URL,
        ccpUrl: instanceURL, // REQUIRED
        loginPopup: true, // optional, defaults to `true`
        loginPopupAutoClose: true, // optional, defaults to `false`
        loginOptions: {
          // optional, if provided opens login in new window
          autoClose: true, // optional, defaults to `false`
          height: 600, // optional, defaults to 578
          width: 400, // optional, defaults to 433
          top: 0, // optional, defaults to 0
          left: 0, // optional, defaults to 0
        },
        region: "us-east-1", // REQUIRED for `CHAT`, optional otherwise
        softphone: {
          // optional, defaults below apply if not provided
          allowFramedSoftphone: true, // optional, defaults to false
          disableRingtone: false, // optional, defaults to false
          // ringtoneUrl: "./ringtone.mp3", // optional, defaults to CCP’s default ringtone if a falsy value is set
        },
        pageOptions: {
          //optional
          enableAudioDeviceSettings: false, //optional, defaults to 'false'
          enablePhoneTypeSettings: true, //optional, defaults to 'true'
        },
        ccpAckTimeout: 5000, //optional, defaults to 3000 (ms)
        ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
        ccpLoadTimeout: 10000, //optional, defaults to 5000 (ms)
        allowFramedSoftphone: true,
      });

      // connect
      //   .getLog()
      //   .warn("The %s broke!", "widget")
      //   .withException("WebSocket connection established!")
      //   .withObject({ a: 1, b: 2 });

      connect.core.onInitialized(function () {
        resolve(true);
      });
    } catch (error) {
      console.log("error", error);
      reject(false);
    }
  });
};
const CCPLogout = () => {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_AWS_CCP_LOGOUT, {
      credentials: "include",
      mode: "no-cors",
    })
      .then((e) => {
        const eventBus = connect.core.getEventBus();
        eventBus.trigger(connect.EventType.TERMINATE);
        connect.core.terminate();
        console.log("TERMINATE");
      })
      .catch((e) => {
        console.log("cpp logout error", e);
      })
      .finally(() => {
        const elem = document.getElementById("container-div-1");
        elem.innerHTML = "";
        resolve();
      });
  });
};

const closeOnCallBtnfn = async () => {
  const closeOnCallBtn = document.getElementById("closeOnCallBtn");
  closeOnCallBtn.click();
};

const openOnCallBtnfn = async () => {
  const openOnCallBtn = document.getElementById("openOnCallBtn");
  openOnCallBtn.click();
};

export { InitCCP, CCPLogout, closeOnCallBtnfn, openOnCallBtnfn };
