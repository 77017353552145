import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { handleScroll } from "../Helper/ScrollHelper";
import { getAgentActivity } from "../Redux/Actions";

export default function AgentActivityList({
  agActivity,
  sdActivity,
  onSelectActivity,
}) {
  const dispatch = useDispatch();

  return agActivity ? (
    <>
      <div className="w-1/2 mr-2 ">
        <div className="w-full px-5 py-4 rounded-md">
          <h4 className="text-xl">Activity</h4>
        </div>
        <div
          className="w-full overflow-auto bg-white shadow rounded-md p-4"
          style={{ height: "570px" }}
          onScroll={(e) =>
            handleScroll(e, () => dispatch(getAgentActivity(true)))
          }
        >
          {Object.keys(agActivity).map((e, i) => {
            const data = agActivity[e];
            return (
              <>
                <div className="w-full mb-4" key={e}>
                  <h5 className="font-bold">
                    {/* Today */}
                    {moment(e).isSame(moment(), "day") ? "Today" : ""}
                    {moment(e).isSame(moment().subtract(1, "days"), "day")
                      ? "Yesterday"
                      : ""}
                  </h5>
                  <span className="text-xs">{e}</span>
                </div>
                {data ? (
                  <>
                    {data.map((item, i) => {
                      return (
                        <div
                          className="w-full flex mb-2"
                          key={item.uid}
                          onClick={() => onSelectActivity(item)}
                        >
                          <div className="w-20 text-center text-sm"></div>
                          <div className="w-full flex justify-between bg-blue-100 px-4 py-2 cursor-pointer items-center rounded-sm">
                            <div className="form-check">
                              <input
                                className="form-check-input appearance-none rounded-full h-3 w-3 border border-black  checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-2 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="radio"
                                name={item.uid}
                                id={item.uid}
                                checked={sdActivity?.uid === item.uid}
                                readOnly={true}
                              />
                              <label
                                className="form-check-label inline-block text-black text-base font-bold"
                                htmlFor="flexRadioDefault1"
                              >
                                {item.type} {item.channel}
                                {" with "}
                                {item?.customerName === "N/A"
                                  ? "Unknown Customer"
                                  : item?.customerName}
                              </label>
                              <p className="text-sm mt-1 pl-6">
                                With{" "}
                                <span>
                                  {item.customer?.phone ?? item.customerPhone}
                                </span>
                              </p>
                            </div>
                            <div>
                              <span className="text-sm">
                                {moment(item.timeStamp).format("LT")}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}
