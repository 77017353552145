import React from "react";
import { Tooltip } from "@material-tailwind/react";

export default function CommingSoonPopUp({ placement = "top", children }) {
  return (
    <Tooltip
      content="Coming Soon"
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0, y: 25 },
      }}
      placement={placement}
      className="z-50"
    >
      {children}
    </Tooltip>
  );
}
