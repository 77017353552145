import React, { useEffect, useState } from "react";
import { sendNotification } from "../Services/notificationServices";
import { showError, showSuccess } from "../Helper/Tost";
import TicketsDropdown from "./TicketsDropdown";
import { useSelector } from "react-redux";

export default function NotificationCallPage({ addClass = "", agent, call }) {
  const [isMsg, setIsMsg] = useState(true);
  const { cuCustomer } = call;

  const [selectedTicket, setSelectedTicket] = useState(null);
  const [sms, setSms] = useState({
    agentId: agent.agentId,
    contactId: call?.cuCallLog?.contactId,
    notification_type: "message",
    address: cuCustomer?.phone,
    content: "",
  });
  const [email, setEmail] = useState({
    agentId: agent.agentId,
    contactId: call?.cuCallLog?.contactId,
    notification_type: "mail",
    address: cuCustomer?.email,
    ticketNumber: "",
    subject: "",
    content: "",
  });
  const [isDisable, setisDisable] = useState(false);
  const redmine = useSelector((state) => state.Redmine);
  const [selectclient, setSelectClient] = useState("");
  // const [selectProject, setSelectProject] = useState("");
  const [selectProject, setSelectProject] = useState(72);

  useEffect(() => {
    resetState();
  }, [isMsg]);

  const notify = async () => {
    console.log("selectedTicket", selectedTicket);
    if (isDisable) {
      return;
    }
    if (!isMsg && (!selectedTicket || selectedTicket?.id === "")) {
      showError("Please select a ticket");
      return;
    }
    const payload = isMsg
      ? sms
      : { ...email, ticketNumber: selectedTicket.id.toString() };
    setisDisable(true);
    console.log(payload);
    const res = await sendNotification(payload);
    if (res.status === 200) {
      resetState();
      showSuccess(res.message);
    } else {
      showError(res.ErrorDescription);
    }
    setisDisable(false);
  };

  const resetState = () => {
    setSelectedTicket("");
    setSelectClient("");
    // setSelectProject("");
    setSms({ ...sms, content: "" });
    setEmail({ ...email, subject: "", content: "", ticketNumber: "" });
  };

  return (
    <div className="w-full flex">
      <div
        className={
          "w-full mr-2 p-5 rounded-md overflow-auto bg-white shadow-sm " +
          addClass
        }
        style={{ paddingBottom: 70 }}
      >
        <div className="w-full flex">
          <div className="form-check">
            <input
              className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault3"
              onClick={() => setIsMsg(true)}
              checked={isMsg}
              readOnly
            />
            <label
              className="form-check-label inline-block text-black text-md"
              htmlFor="flexRadioDefault3"
            >
              Send Message
            </label>
          </div>
          <div className="form-check ml-4">
            <input
              className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault4"
              onClick={() => setIsMsg(false)}
            />
            <label
              className="form-check-label inline-block text-black text-md"
              htmlFor="flexRadioDefault4"
            >
              Send Mail
            </label>
          </div>
        </div>
        {isMsg ? (
          <>
            <div className="w-full pt-6">
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
              >
                Text your message
              </label>
              <textarea
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                id="exampleFormControlTextarea1"
                rows="5"
                placeholder="Type Here..."
                onChange={(e) => setSms({ ...sms, content: e.target.value })}
                value={sms.content}
              ></textarea>
            </div>
            <div className="w-full pt-6 flex justify-end">
              <button
                className=" border-none bg-cyan-500 text-white py-2 px-10 rounded-md"
                onClick={() => notify()}
                disabled={isDisable}
              >
                Send
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="w-full pt-6">
              <div className="flex">
                <div className="w-1/2 mr-4">
                  <div className="mb-3">
                    <label
                      htmlFor="heading"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Project
                    </label>
                    <select
                      className="form-select appearance-none
                                  block
                                  w-full
                                  px-3
                                  py-2.5
                                  text-base
                                  font-normal
                                  text-gray-700
                                  bg-white bg-clip-padding bg-no-repeat
                                  border border-solid border-gray-300
                                  transition
                                  ease-in-out
                                  m-0
                                  focus:outline-none rounded-lg
                                  focus:text-gray-700 focus:bg-white focus:border-black "
                      aria-label="Default select example"
                      value={selectProject}
                      onChange={(e) => setSelectProject(e.target.value)}
                      disabled={true}
                    >
                      <option value="">Select Project</option>
                      {redmine?.projects?.length > 0 &&
                        redmine.projects.map((e, i) => (
                          <option key={i} value={e.id}>
                            {e.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="w-1/2 mr-4">
                  <div className="mb-3">
                    <label
                      htmlFor="heading"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Client
                    </label>
                    <select
                      className="form-select appearance-none
                                  block
                                  w-full
                                  px-3
                                  py-2.5
                                  text-base
                                  font-normal
                                  text-gray-700
                                  bg-white bg-clip-padding bg-no-repeat
                                  border border-solid border-gray-300
                                  transition
                                  ease-in-out
                                  m-0
                                  focus:outline-none rounded-lg
                                  focus:text-gray-700 focus:bg-white focus:border-black "
                      aria-label="Default select example"
                      value={selectclient}
                      onChange={(e) => setSelectClient(e.target.value)}
                    >
                      <option value="">Select Client</option>

                      {redmine?.clients &&
                        redmine.clients.map((e, i) => (
                          <option key={i} value={e.value}>
                            {e.label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="heading"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                >
                  Select Ticket
                </label>
                <TicketsDropdown
                  clientName={selectclient}
                  projectId={selectProject}
                  selectedOption={selectedTicket}
                  setSelectedOption={(e) => setSelectedTicket(e)}
                  disabled={
                    selectclient.length === 0 || selectProject.length === 0
                  }
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="heading"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                >
                  Subject
                </label>
                <input
                  type="text"
                  name="heading"
                  id="heading"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                  placeholder="Type Here..."
                  onChange={(e) =>
                    setEmail({ ...email, subject: e.target.value })
                  }
                  value={email.subject}
                  required
                />
              </div>
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
              >
                Body
              </label>
              <div>
                <textarea
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black "
                  id="exampleFormControlTextarea1"
                  rows="5"
                  placeholder="Type Here..."
                  onChange={(e) =>
                    setEmail({ ...email, content: e.target.value })
                  }
                  value={email.content}
                ></textarea>
              </div>
            </div>
            <div className="w-full pt-6 flex justify-end">
              <button
                className=" border-none bg-cyan-500 text-white py-2 px-10 rounded-md"
                onClick={() => notify()}
              >
                Send
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
