import React from "react";
import { ComingSoonImg } from "../Assets/Images";

function ComingSoon() {
  return <img src={ComingSoonImg} alt="logo" className="w-3/4" />;
}

function ShoWErrorMsg({ msg = "", size = 30 }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: 50,
      }}
    >
      <p
        style={{
          fontSize: size,
          fontWeight: "500",
          color: "rgb(88 122 134)",
        }}
      >
        {msg}
      </p>
    </div>
  );
}

export { ComingSoon, ShoWErrorMsg };
