import API from "../Config/apiConfig";
import endpoints from "../Config/endpoints";
import { getResponse } from "../Helper/APIResponseHelper/APIHelper";

/**
 * Get project list
 */
const getProjectService = async () => {
  try {
    const res = await API.get(endpoints.restapi_token, endpoints.projects);
    console.log("getProjectService:res", res);

    if (res.status === 200) {
      const data = res.data.projects;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:getProjectService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Get clients list
 */
const getClientNameService = async () => {
  try {
    const res = await API.get(endpoints.restapi_token, endpoints.clients);
    console.log("getClientNameService:res", res);

    if (res.status === 200) {
      const data = res.data.clients;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:getClientNameService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Get Tickits By Client name
 */
const getTicketsByClientNameService = async (
  clientName,
  projectId,
  limit = 5,
  offset = 0
) => {
  try {
    const res = await API.get(
      endpoints.restapi_token,
      `${endpoints.tickets}/${clientName}?project_id=${projectId}&limit=${limit}&offset=${offset}`
    );
    console.log("getTicketsByClientNameService:res", res);

    if (res.status === 200) {
      const data = res.data.Tickets;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:getTicketsByClientNameService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Get Tickits By id
 */
const getTicketsByIDService = async (id) => {
  try {
    const res = await API.get(
      endpoints.restapi_token,
      endpoints.ticketById + id
    );

    if (res.status === 200) {
      const data = res.data;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:getTicketsByIDService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Get Tickits By // issue_id / assigned_to_id / cf_8
 */
const getTicketListService = async (type, value, page) => {
  try {
    const res = await API.get(
      endpoints.restapi_token,
      `${endpoints.gettickets}?${type}=${value}&page=${page}`
    );

    console.log("getTicketListService:res", res);
    if (res.status === 200) {
      const data = res.data;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:getTicketListService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};
/**
 * Get Asignee List
 */
const getAsigneeListService = async (page = 1, limit = 5) => {
  try {
    const res = await API.get(
      endpoints.restapi_token,
      `${endpoints.redmineUsers}?page=${page}&limit=${limit}`
    );
    console.log("getAsigneeListService:res", res);

    if (res.status === 200) {
      const data = res.data.users;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:getAsigneeListService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Create ticket
 */
const createTicketService = async (params = {}) => {
  try {
    const payload = {
      ...params,
    };
    console.log("payload", payload);
    const res = await API.post(
      endpoints.restapi_token,
      endpoints.tickets,
      JSON.stringify(payload)
    );
    console.log("createTicketService:res", res);

    if (res.status === 200) {
      const data = res.data;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:createTicketService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * Create ticket
 */
const updateTicketService = async (id, payload) => {
  try {
    console.log("payload", payload);
    const res = await API.put(
      endpoints.restapi_token,
      endpoints.updateTicket + "/" + id,
      JSON.stringify({
        notes: payload.notes,
        clientName: payload.clientName,
        agentName: payload.agentName,
      })
    );
    console.log("updateTicketService:res", res);

    if (res.status === 200) {
      const data = res.data;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:updateTicketService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

export {
  getProjectService,
  getClientNameService,
  getTicketsByClientNameService,
  getTicketsByIDService,
  createTicketService,
  updateTicketService,
  getAsigneeListService,
  getTicketListService,
};
