import React from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";

import { Outlet } from "react-router-dom";
import IncomingCall from "../Components/IncomingCall";

export default function PrivatePageLayout(props) {
  return (
    <div className="w-full h-screen flex flex-col">
      <Navbar />
      <div className="flex h-full">
        <Sidebar />
        <Outlet />
      </div>
      <IncomingCall />
    </div>
  );
}
