/**
 * Auth Actions
 */
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_AUTH_STATE = "UPDATE_AUTH_STATE";

/**
 * Agent Actions
 */
export const AGENT_LOADING = "AGENT_LOADING";
export const SET_AGENT_CONFIG = "SET_AGENT_CONFIG";
export const GET_AGENT_ACTIVITY = "GET_AGENT_ACTIVITY";
export const GET_AGENT_TODOLIST = "GET_AGENT_TODOLIST";
export const UPDATE_AGENT_TODOLIST = "UPDATE_AGENT_TODOLIST";
export const GET_AGENT_STATUS = "GET_AGENT_STATUS";
export const GET_AGENT_SCRIPTSLIST = "GET_AGENT_SCRIPTSLIST";
export const GET_CONTACT_HISTORYBY_ID = "GET_CONTACT_HISTORYBY_ID";
export const GET_AGENT_DASHBOARD_MATRIX = "GET_AGENT_DASHBOARD_MATRIX";

/**
 * Redmine
 */
export const GET_PROJECT_LIST = "GET_PROJECT_LIST";
export const GET_TICKETS_BY_CLIENT_NAME = "GET_TICKETS_BY_CLIENT_NAME";
export const GET_CLIENT_NAME = "GET_CLIENT_NAME";

/**
 * Call
 */
export const GET_CUSTOMER_DETAILS_BY_MOB = "GET_CUSTOMER_DETAILS_BY_MOB";
export const SET_MOB = "SET_MOB";
export const SET_CALL_CONTACT_DATA = "SET_CALL_CONTACT_DATA";
export const RESET_CALL_REDUCER = "RESET_CALL_REDUCER";
export const SET_CALL_STATUS = "SET_CALL_STATUS";
export const SET_CALL_LOG = "SET_CALL_LOG";
