import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi";
import { showError } from "../Helper/Tost";
import { createFirstTimeCustomer } from "../Redux/Actions";

function CallPageForm() {
  const redmine = useSelector((state) => state.Redmine);
  const call = useSelector((state) => state.Call);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: call.phoneNumber,
    client: "",
  });

  const setData = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const submitData = async () => {
    console.log("state", state);

    const schema = Joi.object({
      firstName: Joi.string().min(2).max(20).required(),
      lastName: Joi.string().min(2).max(20).required(),
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
      phone: Joi.string()
        .min(10)
        .max(20)
        // .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.min": "Phone number must have 7-20 digits.",
          "string.max": "Phone number must have 7-20 digits.",
        }),
      client: Joi.string().required(),
    }).unknown(true);

    try {
      await schema.validateAsync(state);
      dispatch(createFirstTimeCustomer(state));
    } catch (err) {
      console.log("submitData", err);
      showError(err.toString());
    }
  };

  return (
    <>
      <div className="w-3/5 overflow-auto bg-white shadow rounded-md px-5 py-6">
        <form className="space-y-6" action="#">
          <div className="w-full flex">
            <div className="w-1/2 mr-2">
              <label
                htmlFor="heading"
                className="block mb-2 text-md font-medium text-black"
              >
                First Name
              </label>
              <input
                type="text"
                name="heading"
                id="heading"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                placeholder="Type Here..."
                required
                value={state.firstName}
                onChange={(e) => {
                  setData("firstName", e.target.value);
                }}
              />
            </div>
            <div className="w-1/2 ml-2">
              <label
                htmlFor="heading"
                className="block mb-2 text-md font-medium text-black"
              >
                Last Name
              </label>
              <input
                type="text"
                name="heading"
                id="heading"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                placeholder="Type Here..."
                required
                value={state.lastName}
                onChange={(e) => {
                  setData("lastName", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="heading"
              className="block mb-2 text-md font-medium text-black"
            >
              Email
            </label>
            <input
              type="email"
              name="heading"
              id="heading"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              placeholder="Type Here..."
              required
              value={state.email}
              onChange={(e) => {
                setData("email", e.target.value);
              }}
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="heading"
              className="block mb-2 text-md font-medium text-black"
            >
              Phone No
            </label>
            <input
              type="text"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              placeholder="Type Here..."
              value={call.phoneNumber}
              disabled
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="heading"
              className="block mb-2 text-md font-medium text-black"
            >
              Client Name
            </label>
            <select
              className="border bg-white border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
              aria-label="Default select example"
              defaultChecked={state.client}
              value={state.client}
              onChange={(e) => {
                setData("client", e.target.value);
              }}
            >
              <option value="">Select Client</option>

              {redmine?.clients &&
                redmine.clients.map((e, i) => (
                  <option key={i} value={e.value}>
                    {e.label}
                  </option>
                ))}
            </select>
          </div>
          <button
            type="button"
            className="w-full text-white focus:outline-none font-medium rounded-sm text-sm px-5 py-2.5 text-center bg-cyan-500"
            onClick={() => submitData()}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default CallPageForm;
