const RA_BASE_URL = process.env.REACT_APP_BASE_END_POINT;
const endpoints = {
  graphql: process.env.REACT_APP_BASE_GQL_END_POINT,
  graphql_token: process.env.REACT_APP_BASE_GQL_END_POINT_TOKEN,
  restapi_token: process.env.REACT_APP_BASE_END_POINT_TOKEN,
  clients: RA_BASE_URL + "/clients",
  projects: RA_BASE_URL + "/projects",
  tickets: RA_BASE_URL + "/tickets",
  updateTicket: RA_BASE_URL + "/ticket", //POST
  ticketById: RA_BASE_URL + "/ticket/", //{id}
  ses: RA_BASE_URL + "/notifications",
  customerHistoryById: RA_BASE_URL + "/customer/history/", //{id}
  customerDetailsByMob: RA_BASE_URL + "/customers?phone=", //{number}
  contactHistory: RA_BASE_URL + "/contact",
  agentsProfile: RA_BASE_URL + "/agents",
  gettickets: RA_BASE_URL + "/tickets", // GET issue_id / assigned_to_id / cf_8
  redmineUsers: RA_BASE_URL + "/users/redmine", // ?page=1&limit=25
  leadsAdd: RA_BASE_URL + "/leads",
  leadsSearch: RA_BASE_URL + "/leads/search", // ?search=Paul&page=2&limit=2
  leadsStatus: RA_BASE_URL + "/leads/status",
  leadsStatusUpdate: RA_BASE_URL + "/leads/update/status",
};

export default endpoints;
