import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showInfo } from "../Helper/Tost";
import { updateAgentTodoList } from "../Redux/Actions";

export default function EditAgentToDo({ data }) {
  const [state, setState] = useState({
    agentId: "",
    id: "",
    notes: "",
    status: "",
    timestamp: "",
    title: "",
  });
  useEffect(() => {
    console.log("data", data);
    setState(Object.assign({}, data));
  }, [data]);

  const dispatch = useDispatch();
  const createToDo = () => {
    console.log("state", state);
    if (state.title.length <= 0 || state.notes.length <= 0) {
      showInfo("Please fill Title and Notes ");
      return;
    }

    dispatch(updateAgentTodoList(state));

    const editAgentToDoBtn = document.getElementById("editAgentToDoBtn");
    editAgentToDoBtn.click();
    setState({
      agentId: "",
      id: "",
      notes: "",
      status: "",
      timestamp: "",
      title: "",
    });
  };

  return (
    <>
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="EditAgentTodo"
        tabIndex="-1"
        aria-labelledby="EditAgentTodoTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className=" w-1/4 modal-dialog modal-dialog-centered relative  pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800 pl-4"
                id="exampleModalScrollableLabel"
              >
                Edit To-Do
              </h5>
              <button
                id="editAgentToDoBtn"
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-cyan-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="pb-4 px-4 lg:px-8">
              <form className="space-y-6" action="#">
                <div>
                  <label
                    htmlFor="heading"
                    className="block mb-2 text-sm font-medium text-gray-900 text-black"
                  >
                    Heading
                  </label>
                  <input
                    type="text"
                    name="heading"
                    id="heading"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                    placeholder="Type Here..."
                    required
                    value={state.title}
                    onChange={(e) =>
                      setState({ ...state, title: e.target.value })
                    }
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900 text-black"
                  >
                    Description
                  </label>
                  <textarea
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-black block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                    id="exampleFormControlTextarea1"
                    rows="7"
                    placeholder="Type Here..."
                    value={state.notes}
                    onChange={(e) =>
                      setState({ ...state, notes: e.target.value })
                    }
                  ></textarea>
                </div>
                <button
                  type="button"
                  className="w-full text-white focus:outline-none font-medium rounded-sm text-sm px-5 py-2.5 text-center bg-cyan-500"
                  onClick={() => createToDo()}
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
