import API from "../Config/apiConfig";
import endpoints from "../Config/endpoints";
import { getResponse } from "../Helper/APIResponseHelper/APIHelper";

/**
 * add Lead Service
 */
const addLeadService = async (params) => {
  try {
    const payload = {
      Last_Name: params?.Last_Name,
      First_Name: params?.First_Name,
      Email: params?.Email,
      Phone_Number: params?.Phone_Number,
      Company: params?.Company,
      Lead_Owner: params?.Lead_Owner,
      Lead_Source: params?.Lead_Source,
      Description: params?.Description,
      Website: params?.Website,
    };

    const res = await API.post(
      endpoints.restapi_token,
      endpoints.leadsAdd,
      JSON.stringify(payload)
    );

    console.log("addLeadService:res", res);
    if (res.status === 200) {
      const data = res.data;
      return getResponse(res.status, {
        data,
      });
    } else {
      return {
        status: res.status,
        ErrorDescription: res.data?.ErrorDescription,
      };
    }
  } catch (error) {
    console.log("error:addLeadService", error);
    return getResponse(error.status, null, error.data.ErrorDescription);
  }
};

/**
 * search Lead Service
 */
const searchLeadService = async (param, type, page = 1, limit = 5) => {
  try {
    const res = await API.get(
      endpoints.restapi_token,
      `${endpoints.leadsSearch}?${type}=${param}&page=${page}&limit=${limit}`
    );

    console.log("searchLeadService:res", res);
    if (res.status === 200) {
      const data = res.data;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:searchLeadService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

/**
 * update Lead Status Service
 */
const updateLeadStatusService = async (payload) => {
  try {
    const res = await API.post(
      endpoints.restapi_token,
      endpoints.leadsStatusUpdate,
      JSON.stringify(payload)
    );

    console.log("updateLeadStatusService:res", res);
    if (res.status === 200) {
      const data = res.data;
      return getResponse(res.status, {
        data,
      });
    } else {
      return {
        status: res.status,
        ErrorDescription: res.data?.ErrorDescription,
      };
    }
  } catch (error) {
    console.log("error:updateLeadStatusService", error);
    return getResponse(error.status, null, error.data.ErrorDescription);
  }
};

/**
 * get Lead Status Service
 */
const getLeadStatusService = async () => {
  try {
    const res = await API.get(endpoints.restapi_token, endpoints.leadsStatus);

    console.log("getLeadStatusService:res", res);
    if (res.status === 200) {
      const data = res.data;
      return getResponse(res.status, {
        data,
      });
    } else {
      return getResponse(res.status, null, res.data?.errors);
    }
  } catch (error) {
    console.log("error:getLeadStatusService", error);
    return getResponse(error.status, null, error.data.errors);
  }
};

export {
  addLeadService,
  searchLeadService,
  updateLeadStatusService,
  getLeadStatusService,
};
