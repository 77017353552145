import React, { useEffect, useState } from "react";
import { BsHeadphones } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { loadTranscriptDataService } from "../Services/callServices";
import Loader from "./Loader";
import { ShoWErrorMsg } from "./HelperComponent";

export default function AudioTranscript({ sdActivity }) {
  useEffect(() => {
    loadTranscriptData();
  }, [sdActivity.transcriptionLink]);
  const [state, setState] = useState({
    data: [],
    loading: false,
    errorMsg: "",
  });

  const loadTranscriptData = async () => {
    try {
      setState((pS) => ({ ...pS, loading: true }));
      if (sdActivity.transcriptionLink) {
        const url = sdActivity.transcriptionLink;
        const res = await loadTranscriptDataService(url);
        console.log("resloadTranscriptData", res);
        setState((pS) => ({ ...pS, data: res.data }));
      } else {
        setState((pS) => ({
          ...pS,
          errorMsg: "No Transcript URL associated to this call.",
        }));
      }
    } catch (error) {
      console.log("error:loadTranscriptData", error);
      setState((pS) => ({ ...pS, errorMsg: "Unable to get transcript data" }));
    } finally {
      setState((pS) => ({ ...pS, loading: false }));
    }
  };

  return (
    <div className="w-1/2 ml-2">
      <div className="p-5 rounded-md bg-white shadow-md">
        <div className="flex-1 justify-between flex flex-col">
          <div className="w-full mb-7">
            <p className="text-lg">Call Transcript</p>
          </div>
          {state.errorMsg.length > 0 && (
            <ShoWErrorMsg msg={state.errorMsg} size={20} />
          )}
          {state.loading ? (
            <Loader />
          ) : (
            <div
              id="messages"
              className="flex flex-col space-y-4 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
              style={{ height: 300 }}
            >
              {state.data?.map((e, i) =>
                e.ParticipantRole === "CUSTOMER" ? (
                  <div key={i} className="chat-message">
                    <div className="flex items-end">
                      <div className="flex flex-col space-y-2 text-sm max-w-sm mx-2 order-2 items-start">
                        <div>
                          <span className="px-4 py-4 rounded-lg inline-block rounded-bl-none bg-sky-100 text-gray-600">
                            {e.Content}
                          </span>
                        </div>
                      </div>
                      <div className="flex w-16 items-center">
                        <AiOutlineUser className="text-cyan-500 mr-1" />
                        <p>User</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="chat-message">
                    <div className="flex items-end justify-end">
                      <div className="flex flex-col space-y-2 text-sm max-w-sm mx-2 order-1 items-end">
                        <div>
                          <span className="px-4 py-4 rounded-lg inline-block rounded-bl-none bg-sky-100 text-gray-600">
                            {e.Content}
                          </span>
                        </div>
                      </div>
                      <div className="flex w-16 items-center order-2 ml-2">
                        <p>Agent</p>
                        <BsHeadphones className="text-cyan-500 ml-1" />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
