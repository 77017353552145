import React from "react";

export const openBtn = async () => {
  const close = document.getElementById("openOnStatusChangeBtn");
  close.click();
};
export function ConfirmAlertPCWToAv(props) {
  const closeBtn = async () => {
    const close = document.getElementById("closeOnStatusChangeBtn");
    close.click();
  };

  return (
    <>
      <button
        id="openOnStatusChangeBtn"
        type="button"
        class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        data-bs-toggle="modal"
        data-bs-target="#openOnStatusChangeID"
        style={{ display: "none" }}
      ></button>

      <button
        id="closeOnStatusChangeBtn"
        type="button"
        className="btn-close box-content w-4 h-4 p-1 text-cyan-500 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
        data-bs-dismiss="modal"
        data-bs-target="#openOnStatusChangeID"
        aria-label="Close"
        style={{ display: "none" }}
      ></button>
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="openOnStatusChangeID"
        tabindex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog w-1/4 modal-dialog-centered relative pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-clip-padding rounded-md outline-none text-current">
            <div
              className="modal-header flex flex-shrink-0 items-center justify-center py-7 px-6 rounded-t-md"
              style={{ background: "#fff" }}
            >
              <div className="flex items-center justify-center flex-col">
                <p
                  className="text-white font-semibold"
                  style={{ color: "#000", fontSize: 20 }}
                >
                  Do you want to close the existing contact?
                </p>
              </div>
            </div>
            <div
              className="modal-body relative flex justify-between"
              style={{ height: 50, background: "#fff" }}
            >
              <button
                onClick={() => {
                  props.fn();
                  closeBtn();
                }}
                // className="w-1/2 flex justify-center items-center rounded-bl-md"
                className=" flex justify-center items-center border-none bg-cyan-500 text-white py-2 px-10 rounded-md"
                style={{ width: "40%", height: "80%", marginLeft: 10 }}
                data-bs-toggle="modal"
                data-bs-target="#openOnStatusChangeID"
              >
                <p className="text-lg text-white">Yes</p>
              </button>
              <button
                onClick={() => closeBtn()}
                className=" flex justify-center items-center border-none bg-cyan-500 text-white py-2 px-10 rounded-md"
                style={{ width: "40%", height: "80%", marginRight: 10 }}
                data-bs-toggle="modal"
                data-bs-target="#openOnStatusChangeID"
              >
                <p className="text-lg text-white">Cancel</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
