import {
  addTodoListService,
  agentlistScriptsService,
  createAgentJourneyService,
  getAgentDashboardMatrixService,
  getHistoryBycustomerIdService,
  loadAgentActvityService,
  loadTodoListService,
  sendAgentProfileDetails,
  updateAgentStatusService,
  updateTodoListService,
  updateTodoListStatusService,
} from "../../Services/agentServices";
import {
  SET_AGENT_CONFIG,
  GET_AGENT_ACTIVITY,
  GET_AGENT_TODOLIST,
  UPDATE_AGENT_TODOLIST,
  GET_CONTACT_HISTORYBY_ID,
  GET_AGENT_DASHBOARD_MATRIX,
  SET_CALL_STATUS,
} from "../actionTypes";
import moment from "moment";
import { mergeDeep } from "../../Helper/Others";
import { showError, showSuccess } from "../../Helper/Tost";

const setAgentConfig = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const connect = window.connect;
      connect.agent(async (agent) => {
        const agData = agent.getConfiguration();
        console.log("agData", agData);

        sendAgentProfileDetails({
          agentId: agData.username,
          name: agData.name,
          email: agData.username,
        });

        agent.onStateChange(async (agentStateChange) => {
          let cuStateName = agentStateChange.newState;
          /**
           * set name and hit api
           */

          // if (
          //   agentStateChange.oldState === "AfterCallWork" &&
          //   agentStateChange.newState === "Available"
          // ) {
          //   dispatch({
          //     type: RESET_CALL_REDUCER,
          //   });
          // }

          if (cuStateName === "PendingBusy" || cuStateName === "Busy") {
            cuStateName = "Busy";
            dispatch({
              type: SET_CALL_STATUS,
              data: { callStatus: true },
            });
            dispatch(updateAgentStatus(cuStateName));
          } else if (cuStateName === "AfterCallWork") {
            cuStateName = "PCW";
            dispatch({
              type: SET_CALL_STATUS,
              data: { callStatus: false },
            });
            dispatch(updateAgentStatus(cuStateName));
          } else if (agentStateChange.oldState !== "Init") {
            dispatch(updateAgentStatus(cuStateName));
          }

          const data = agent
            .getAgentStates()
            .findIndex((e) => e.name === agentStateChange.newState);
          const time = moment().toISOString();

          dispatch({
            type: SET_AGENT_CONFIG,
            data: {
              agentCuStatusName: cuStateName,
              agentCuStatusIndex: data,
              agentCuStatusStartTime: time,
            },
          });
        });
        dispatch({
          type: SET_AGENT_CONFIG,
          data: {
            agentId: agData.username,
            agentConfigCpp: {
              ...agData,
              queueType: agData?.routingProfile.defaultOutboundQueue.name,
            },
            agentStatus: agent.getAgentStates(),
          },
        });
        resolve();
      });
    });
  };
};

const getAgentActivity = (loadMore = false) => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    const agentNextToken = loadMore
      ? getState()?.Agent?.agActivityNextToken
      : "";
    const res = await loadAgentActvityService(agent.agentId, agentNextToken);
    dispatch({
      type: GET_AGENT_ACTIVITY,
      data: {
        agActivity: loadMore
          ? mergeDeep(agent?.agActivity ? agent?.agActivity : {}, res.data)
          : res.data,
        agActivityNextToken: res.nextToken,
      },
    });
  };
};

const getAgentTodoList = (loadMore = false) => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    const nextToken = loadMore ? getState()?.Agent?.agTodoListNextToken : "";
    const res = await loadTodoListService(agent.agentId, nextToken);
    dispatch({
      type: GET_AGENT_TODOLIST,
      data: {
        agTodoList: loadMore ? agent.agTodoList.concat(res.data) : res.data,
        agTodoListNextToken: res.nextToken,
      },
    });
  };
};

const addAgentTodoList = (payload) => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    const res = await addTodoListService(agent.agentId, payload);
    if (res.status === 200) {
      showSuccess("To-Do List Created Successfully");
      dispatch(getAgentTodoList());
    } else {
      showError("Failed to create To-Do List ");
    }
  };
};

const updateAgentTodoListStatus = (id) => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    const agTodoList = getState().Agent.agTodoList;
    const res = await updateTodoListStatusService(agent.agentId, id);
    if (res.status === 200) {
      agTodoList[agTodoList.findIndex((e) => e.id === id)].status =
        res.data.status;
      dispatch({
        type: UPDATE_AGENT_TODOLIST,
        data: agTodoList,
      });
    } else {
    }
  };
};

const updateAgentTodoList = (data) => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    const agTodoList = getState().Agent.agTodoList;
    const payload = {
      id: data.id,
      title: data.title,
      notes: data.notes,
    };
    console.log("payload", payload);
    const res = await updateTodoListService(agent.agentId, payload);
    if (res.status === 200) {
      const index = agTodoList.findIndex((e) => e.id === data.id);
      agTodoList[index].title = res.data.title;
      agTodoList[index].notes = res.data.notes;
      dispatch({
        type: UPDATE_AGENT_TODOLIST,
        data: agTodoList,
      });
    } else {
    }
  };
};

const getAgentListScripts = (loadMore = false) => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    const nextToken = loadMore ? getState()?.Agent?.agagScriptListToken : "";
    const res = await agentlistScriptsService(
      agent?.agentConfigCpp.routingProfile.defaultOutboundQueue.queueId,
      nextToken
    );
    dispatch({
      type: GET_AGENT_TODOLIST,
      data: {
        agScriptList: loadMore ? agent.agScriptList.concat(res.data) : res.data,
        agagScriptListToken: res.nextToken,
      },
    });
  };
};

const getHistoryBycustomerId = (loadMore = false, id = "") => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    const call = getState()?.Call;
    const cuId = id === "" ? call?.cuCustomer?.customerId : id;
    const nextToken = loadMore ? getState()?.Agent?.contactHistoryToken : "";
    const res = await getHistoryBycustomerIdService(cuId, nextToken);
    console.log("res:getHistoryBycustomerId", res);
    if (res.status === 200) {
      dispatch({
        type: GET_CONTACT_HISTORYBY_ID,
        data: {
          contactHistory: loadMore
            ? agent.contactHistory.concat(res.data)
            : res.data,
          contactHistoryToken: res.nextToken,
        },
      });
    }
  };
};

const getAgentDashboardMatrix = () => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    const res = await getAgentDashboardMatrixService(agent.agentId);
    if (res.status === 200) {
      dispatch({
        type: GET_AGENT_DASHBOARD_MATRIX,
        data: { agDBMatrix: res.data },
      });
    }
  };
};

const updateAgentStatus = (statusName) => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    const time = moment().toISOString();
    if (agent.agentCuStatusName != null) {
      const payload = {
        agentId: agent.agentId,
        cuTime: time,
        cuStatus: statusName.toLowerCase(),
        oldTime: agent.agentCuStatusStartTime,
        oldStatus: agent.agentCuStatusName.toLowerCase(),
      };
      await updateAgentStatusService(payload);
      dispatch({
        type: SET_AGENT_CONFIG,
        data: { agentCuStatusStartTime: time },
      });
      dispatch(getAgentDashboardMatrix());
    }
  };
};

const updateAgentStatusRepeat = () => {
  return async (dispatch, getState) => {
    const agent = getState()?.Agent;
    const time = moment().toISOString();
    if (agent.agentCuStatusName != null) {
      const payload = {
        agentId: agent.agentId,
        cuTime: time,
        cuStatus: agent.agentCuStatusName.toLowerCase(),
        oldTime: agent.agentCuStatusStartTime,
        oldStatus: agent.agentCuStatusName.toLowerCase(),
      };
      await updateAgentStatusService(payload);
      dispatch({
        type: SET_AGENT_CONFIG,
        data: { agentCuStatusStartTime: time },
      });
      setTimeout(() => {
        dispatch(getAgentDashboardMatrix());
      }, 5000);
    }
  };
};

const createAgentJourney = (params) => {
  return async (dispatch, getState) => {
    const call = getState()?.Call;
    if (call.contactState != null) {
      const payload = {
        ...call.cuCallLog,
        ...params,
        customerId: call?.cuCustomer?.customerId ?? "N/A",
        customerPhone: call.phoneNumber,
        timeStamp: moment().toISOString(),
      };
      await createAgentJourneyService(payload);
    }
  };
};

export {
  setAgentConfig,
  getAgentActivity,
  getAgentTodoList,
  addAgentTodoList,
  updateAgentTodoListStatus,
  getAgentListScripts,
  getHistoryBycustomerId,
  getAgentDashboardMatrix,
  updateAgentStatus,
  createAgentJourney,
  updateAgentTodoList,
  updateAgentStatusRepeat,
};
