import axios from "axios";
import { store } from "../Redux/store";

const fetchClient = (key = null) => {
  // const auth = store.getState().Auth;
  const defaultOptions = {
    // baseURL: auth.env.BASE_URL,
    headers: {
      // "Access-Control-Allow-Origin": "*",
      "x-api-key": key,
      "Content-Type": "application/json",
      // Authorization: auth.token,
    },
  };
  // Create instance
  // @ts-ignore
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  // instance.interceptors.request.use(function (config) {
  //   const token = auth.token;
  //   config.headers.Authorization = token ? `Bearer ${token}` : "";
  //   return config;
  // });

  return instance;
};
const API = {
  get: async (key = null, path) => {
    try {
      return await fetchClient(key).get(path);
    } catch (error) {
      console.log("error", error);
      return { status: error?.response?.status, data: error?.response?.data };
    }
  },
  post: async (key = null, path, params) => {
    try {
      return await fetchClient(key).post(path, params);
    } catch (error) {
      return { status: error?.response?.status, data: error?.response?.data };
    }
  },
  put: async (key = null, path, params) => {
    try {
      return await fetchClient(key).put(path, params);
    } catch (error) {
      return { status: error?.response?.status, data: error?.response?.data };
    }
  },
  delete: async (key = null, path) => {
    try {
      return await fetchClient(key).delete(path);
    } catch (error) {
      return { status: error?.response?.status, data: error?.response?.data };
    }
  },
  // upload(path: string, params: any) {
  //   return fetchClient().post(path, params, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   })
  // },
};
// store.subscribe(fetchClient);
export default API;
